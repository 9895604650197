import styled from 'styled-components'

export const FooterHome = styled.footer`
  background-color: #f7f7f8;
  & .container {
    border-top: 1px solid #dbdbdb;
  }
  & a.map,
  & a.terms {
    color: #858484 !important;
    text-decoration: underline;
    &:hover {
      color: #858484 !important;
    }
  }
`
