import React, { useState, useCallback } from 'react'
import Button from 'react-bootstrap/Button'
import { MdAdd } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { CgSmileNeutral, CgSmile, CgSmileSad } from 'react-icons/cg'

import eventIcon from '../../../../assets/images/icons/eventicon.svg'
import { Sublists } from '../../../../components/Tiers'
import { CustomTabs } from '../../../../styles/commonStyles'
import { GET_AVAILABLE_PACKAGES } from '../../../../graphql'
import { faceIconColor } from '../../../../styles/themeColors'
import { getImageUrl, getCheckIn } from '../../../../common'
import { FormatNumber, Loader } from '../../../../components'
import {
  Booking,
  BookingStatus,
  EventComponent,
  Guest,
} from '../../../../types'

import { BookingsContainer, RsvpManagerContainer } from './style'
import { sortPackages } from './guestListFunctions'

import { YourPartyTable, BookingForGuest } from '.'

const ManageRSVP: React.FC<{
  eventId: string
  currentGuest: Guest
  bookings: Booking[]
}> = ({ eventId, bookings = [], currentGuest }) => {
  const { data, loading } = useQuery(GET_AVAILABLE_PACKAGES, {
    variables: { eventId, guestId: currentGuest?.id },
    fetchPolicy: 'network-only',
  })

  // Memoize filter function to optimize performance
  // Recreates only when 'bookings' changes to avoid re-rendering the component
  const getBookingsByEC = useCallback(
    (ecId: string) => bookings.filter(b => b.eventComponent.id === ecId),
    [bookings]
  )

  if (loading) return <Loader text="Loading options..." />
  const availablePackages: EventComponent[] = data?.availablePackages ?? []
  if (availablePackages.length <= 0) {
    return (
      <div className="text-center py-3 py-lg-5">
        <h4>No available packages</h4>
      </div>
    )
  }

  const className = availablePackages.length === 1 ? 'single' : ''
  const orderedPackages = sortPackages(availablePackages)

  const handleClickMoreDetails = (container: string) => {
    // this is just to add or remove the class collapsed/expanded so its not necessary to use the state
    // this way we avoid re-rendering the component
    const containerEl = document.querySelector(`.details-${container}`)
    const ctaText = document.querySelector(`#cta-text-${container}`)
    if (!containerEl || !ctaText) return

    const isCollapsed = containerEl.classList.toggle('collapsed') // classList.toggle() returns a boolean value if the class was added and false if removed
    ctaText.textContent = isCollapsed ? 'View' : 'Hide'
  }

  const getFaceIcons = (
    bookingList: Booking[],
    ecId: string
  ): React.ReactElement[] => {
    if (bookingList.length <= 0) {
      return [
        <CgSmileNeutral
          size={35}
          color={faceIconColor.neutral}
          title="No Bookings"
          key={`no-bookings-${ecId}`}
        />,
      ]
    }
    return bookingList.flatMap(booking =>
      booking.bookingAddOn
        .filter(el => el.bookingGuest)
        .map(g => {
          const name = `${g.bookingGuest?.firstName} ${g.bookingGuest?.lastName}`
          const isBooked = booking.status === BookingStatus.Booked
          const Icon = isBooked ? CgSmile : CgSmileSad
          return (
            <Icon
              key={`face-${g.id}`}
              size={35}
              color={isBooked ? faceIconColor.happy : faceIconColor.sad}
              title={name}
            />
          )
        })
    )
  }

  return (
    <BookingsContainer className={className}>
      {orderedPackages.map(ec => {
        const bookingsByEC = getBookingsByEC(ec.id)
        return (
          <React.Fragment key={`row-${ec.id}`}>
            <div className={`b-row ${className}`}>
              <div className="offering-data">
                <p className="fz-18 fwsb mb-1">
                  {ec?.alias ?? ec.offering.name}
                  <span className="d-block fz-14 text-primary">
                    {ec.offering.provider.name}
                  </span>
                </p>
                <p className="d-flex m-0 fwsb fz-15">
                  {getCheckIn(ec.date, 'dddd, MMMM DD, YYYY')}
                </p>
                <div className="fz-16 fwsb py-2">
                  Price: <FormatNumber n={ec.offering.price} />
                </div>

                {bookingsByEC.length > 0 ? (
                  <Button
                    className="px-3 py-1"
                    onClick={() => handleClickMoreDetails(ec.id)}
                  >
                    <MdAdd size={18} className="svg-top1 mr-1" />
                    <span id={`cta-text-${ec.id}`}>View</span> RSVP
                  </Button>
                ) : (
                  <BookingForGuest eventComponent={ec} guest={currentGuest} />
                )}

                <Sublists
                  tiers={ec.tiers}
                  className="mt-3"
                  badgeClassName="fz-11"
                />
              </div>

              <div
                className="mx-auto px-3 text-center pb-2 pb-lg-0"
                style={{ maxWidth: '200px' }}
              >
                <p className="fwsb mb-1">Your party</p>
                {getFaceIcons(bookingsByEC, ec.id)}
              </div>

              <img
                src={getImageUrl(ec.offering.mainImage, '400x250')}
                alt={ec.offering.name}
              />
            </div>
            <YourPartyTable bookings={bookingsByEC} />
          </React.Fragment>
        )
      })}
    </BookingsContainer>
  )
}

export const PackageBookingsModal: React.FC<{
  bookings: Booking[]
  currentGuest?: Guest
}> = ({ bookings = [], currentGuest }) => {
  const { eventId } = useParams<{ eventId: string }>()
  const [activeTab, setActiveTab] = useState(0)

  // Define the structure for tabs
  // This makes it easy to add or modify tabs in the future
  // we are going to keep it this way for now in case we need to add more tabs we can just add them here without changing the logic
  const tabs = [
    {
      index: 0,
      icon: eventIcon,
      label: `RSVP List - ${currentGuest?.firstName} ${currentGuest?.lastName}`,
    }, // we only have 1 tab for the moment
  ]

  if (!currentGuest) return null
  return (
    <RsvpManagerContainer>
      <CustomTabs className="mb-3">
        {tabs.map(tab => (
          <button
            key={`rsvp-tab-${tab.index}`}
            type="button"
            onClick={() => activeTab !== tab.index && setActiveTab(tab.index)}
            className={activeTab === tab.index ? 'active' : ''}
          >
            <img src={tab.icon} alt={tab.label} />
            {tab.label}
          </button>
        ))}
      </CustomTabs>
      {currentGuest ? (
        <ManageRSVP
          currentGuest={currentGuest}
          eventId={eventId}
          bookings={bookings}
        />
      ) : (
        <div className="text-center py-3 py-lg-5">
          <h4>No guest selected</h4>
        </div>
      )}
    </RsvpManagerContainer>
  )
}
