import React from 'react'

export const Conversionly: React.FC = () => {
  return (
    <>
      <div className="text-primary text-center px-3 pt-3">
        <h2 className="fz-24">
          DISCOVER HOW WE HELP COUPLES PLAN STRESS-FREE & UNFORGETTABLE
          DESTINATION WEDDINGS
        </h2>
        <span className="fz-14">
          You Will Then Be Redirected To A Calendar To Book A Time
        </span>
      </div>
      <iframe
        id="Lbjn6D34lpgLqKCiUgnh"
        title="FREE Consultation - Kiss and Tell Weddings"
        style={{
          border: 'none',
          minHeight: '445px',
          overflow: 'hidden',
          width: '100%',
        }}
        src="https://www.cnvrsnly.com/widget/survey/Lbjn6D34lpgLqKCiUgnh"
      />
    </>
  )
}
