import React, { Dispatch, SetStateAction } from 'react'

import { IframeYoutube } from '../../../common'
import { SigninSignup } from '../../../components'
import { Block, BlockContainer } from '../style'

interface IHowWeWorkBody {
  registerModal: boolean
  openRegisterModal: Dispatch<SetStateAction<boolean>>
}

export const HowWeWorkBody: React.FC<IHowWeWorkBody> = ({
  children,
  registerModal,
  openRegisterModal,
}) => {
  return (
    <>
      <p className="px-0 px-md-5 mx-3 fz-18 fz-sm-14 intro-text">
        We get it! Planning your wedding, girls trip, guys trip, family reunion,
        etc… is a total pain! Uncomfortable follow ups, collecting of money and
        managing moving parts on multiple platforms, NO MORE! We’ve got you
        through your big day in one streamlined place.
      </p>
      <IframeYoutube
        videoId="3HYsa5vravI"
        width="268px"
        height="476px"
        type="vertical"
        controls={false}
        playing
        mobileFrame
        customVolumeControl
        muted
      />
      <SigninSignup
        open={registerModal}
        onClose={() => openRegisterModal(false)}
      />
      {children}
      <BlockContainer>
        <span className="fz-20 py-3 d-block text-center">
          Need to know more?
        </span>
        <Block>
          <span className="number">1</span>
          <p>Create Event</p>
          <ul className="m-0">
            <li>Name your event</li>
            <li>Start inviting guests</li>
            <li>Begin the conversation with input and polling of guests</li>
          </ul>
        </Block>
        <Block>
          <span className="number">2</span>
          <p>Build Package</p>
          <ul className="m-0">
            <li>Select your celebration venue</li>
            <li>Add your group stay with or without a venue package</li>
            <li>
              Customize your gathering by group size, date and length of time
            </li>
          </ul>
        </Block>
        <Block>
          <span className="number">3</span>
          <p>Confirm with Vendor</p>
          <ul className="m-0">
            <li>Confirm availability and pricing</li>
            <li>Fine tune the details</li>
            <li>Reserve your get together with a simple deposit</li>
          </ul>
        </Block>
        <Block>
          <span className="number">4</span>
          <p>Share the Details</p>
          <ul className="m-0">
            <li>Guests access event and accommodation details</li>
            <li>
              Segment conversations and details to specific guests and vendors
            </li>
            <li>Group and sub-group chats for multiple events in one place</li>
          </ul>
        </Block>
        <Block>
          <span className="number">5</span>
          <p>Guest Attendance & Travel Management</p>
          <ul className="m-0">
            <li>Guests confirm their attendance and book their own rooms</li>
            <li>
              Group payment options allowing hosts to discount some or all guest
              stays
            </li>
            <li>
              Host and vendor receive automated updates as each guest books
            </li>
          </ul>
        </Block>
        <Block>
          <span className="number">6</span>
          <p>Enjoy The Celebration!</p>
          <ul className="m-0">
            <li>Get everyone there without the headache</li>
            <li>
              Streamlined solution tying together yours and your guests’ needs
            </li>
            <li>
              Kiss the multi platform follow up, rooming lists and nagging
              goodbye
            </li>
          </ul>
        </Block>
      </BlockContainer>
    </>
  )
}
