import React from 'react'
import { MdEdit } from 'react-icons/md'
import { Link } from 'react-router-dom'
import Table from 'react-bootstrap/Table'
import { TbCrown } from 'react-icons/tb'
import { CgSmile, CgSmileSad } from 'react-icons/cg'

import { useCurrentUser } from '../../../../common'
import { FormatNumber, RenderPhone } from '../../../../components'
import { Booking, BookingStatus } from '../../../../types'
import { addonMetadata } from '../../../../common/constants'
import { ChildrenIcon } from '../../../../components/customIcons'
import { faceIconColor, salmon } from '../../../../styles/themeColors'
import { orderBookingGuests } from '../../../Booking/components/bookingFunctions'

import { bookingStatusAux } from './guestListFunctions'

export const YourPartyTable: React.FC<{ bookings: Booking[] }> = ({
  bookings = [],
}) => {
  const { email } = useCurrentUser()

  if (bookings.length <= 0) return null
  const { eventComponent: ec } = bookings[0]

  const tableRows = bookings.map(el => {
    const { status } = el
    const icon =
      status === BookingStatus.Booked ? (
        <CgSmile size={22} color={faceIconColor.happy} title="Booked" />
      ) : (
        <CgSmileSad size={22} color={faceIconColor.sad} title="Declined" />
      )
    const { bookingAddOn } = el
    const guests = bookingAddOn.filter(b => b.bookingGuest)
    const guestsRows = orderBookingGuests({
      guests,
      currentUserEmail: email,
    }).map((g, idx) => {
      const { bookingGuest: guest, offeringAddOn } = g
      const { kid } = addonMetadata

      return (
        <tr key={`your-party-guest-${g.id}`}>
          <td className={idx > 0 ? 'px-5' : ''}>
            {idx === 0 && <TbCrown className="mb-1" size={22} color={salmon} />}{' '}
            {guest?.firstName} {guest?.lastName}
          </td>
          <td>{icon}</td>
          <td className="px-0">
            {offeringAddOn.metadata?.subtype === kid && (
              <ChildrenIcon color={salmon} />
            )}
          </td>
          <td>{guest?.email}</td>
          <td>
            <RenderPhone phone={guest?.phone} />
          </td>
        </tr>
      )
    })

    return (
      <React.Fragment key={`your-party-booking-${el.id}`}>
        <tr className="bg-lgray">
          <td className="py-2 pl-2">
            {el.guestPrice.total > 0 && (
              <span className="fwsb fz-16">
                Total: <FormatNumber n={el.guestPrice.total} />
              </span>
            )}
          </td>
          <td className="py-2">
            <Link to={`/booking/${el.id}/edit`} className="fwn fz-14">
              Edit RSVP <MdEdit size={18} className="svg-top1" />
            </Link>
          </td>
          <td colSpan={4} className="py-2">
            {bookingStatusAux(el, 'fwsb fz-16')}
          </td>
        </tr>
        {guestsRows}
      </React.Fragment>
    )
  })

  return (
    <div className={`more-details details-${ec.id} collapsed`}>
      <Table borderless size="sm" className="w-90 mx-auto">
        <tbody>{tableRows}</tbody>
      </Table>
    </div>
  )
}
