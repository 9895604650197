import React, { useState } from 'react'

import { CustomTabs } from '../../../styles/commonStyles'

// we are just removing register instead of refactoring the whole component in case we need it in the future o if we need more tabs
export const SignInOutTabs: React.FC<{ loginChildren: React.ReactNode }> = ({
  loginChildren,
}) => {
  const [activeTab, setActiveTab] = useState(0)

  const tabs = [
    {
      index: 0,
      label: 'Login',
    },
  ]

  const handleTabChange = (index: number) => {
    if (activeTab !== index) {
      setActiveTab(index)
    }
  }

  return (
    <>
      <CustomTabs className="mb-5">
        {tabs.map(tab => (
          <button
            key={`rsvp-tab-${tab.index}`}
            type="button"
            onClick={() => handleTabChange(tab.index)}
            className={activeTab === tab.index ? 'active' : ''}
          >
            {tab.label}
          </button>
        ))}
      </CustomTabs>
      {activeTab === 0 && loginChildren}
    </>
  )
}
