import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'
import { MdCancel } from 'react-icons/md'

import { SiteLogo } from '../customIcons'
import { salmon } from '../../styles/themeColors'
import { ISignUpInForValidations } from '../../types'

import { Signin } from './Signin'
import { SignInUpContainer } from './style'
import { SignInOutTabs } from './components'

export const SignUpSignInForValidations: React.FC<ISignUpInForValidations> = ({
  open,
  onClose,
  title,
  text,
  preloadedData,
  afterLoginCallback,
}) => {
  const close = () => {
    onClose()
    // function to close the modal in case we need extra logic like the other login modal
  }

  return (
    <Modal centered show={open} onHide={onClose} className="modal-rounded-0">
      <SignInUpContainer className="validations">
        <MdCancel size={20} className="closeModal cursor" onClick={onClose} />
        <Modal.Body className="text-center">
          <SiteLogo width="57px" height="40px" color={salmon} />
          <h4 className="mt-1">{title}</h4>
          {/* eslint-disable-next-line react/no-danger */}
          {text && <p>{text}</p>}
        </Modal.Body>

        <SignInOutTabs
          loginChildren={
            <div className="px-3 px-lg-5 pb-4">
              <Signin
                onClose={close}
                preloadedData={preloadedData}
                callback={afterLoginCallback}
              />
              <Link
                to="/reset-password"
                className="w-fit-content d-block fz-14 mt-3 mx-auto"
                onClick={onClose}
              >
                Forgot password?
              </Link>
            </div>
          }
        />
      </SignInUpContainer>
    </Modal>
  )
}
