import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'

import user from '../../../assets/images/rsvp/user.jpg'
import { SigninSignup } from '../../../components'
import { Guest, ISignInUpPreloadedData } from '../../../types'
import { useCurrentUser } from '../../../common'

export const PreInvite: React.FC<{
  data: Guest
  preloadedData?: ISignInUpPreloadedData
}> = ({ data, preloadedData }) => {
  const [authModal, openAuthModal] = useState(false)
  const { isAuthenticated } = useCurrentUser()
  const { mainImage } = data.event

  return (
    <>
      <h2 className="text-center text-primary font-lf fz-50">
        {data?.event?.name}
      </h2>
      <Card
        body
        style={{ maxWidth: '700px' }}
        className="mx-auto shadow-sm pb-4"
      >
        <div>
          <p className="text-center fz-16 fwsb">You’re invited to help plan!</p>
          <img src={mainImage || user} alt="User" className="mw-landing-img" />
        </div>
        <p className="text-center pt-3 px-0 px-sm-2">
          Hey {data.firstName}! <br />
          <br />
          We’re thrilled to be planning our big event and as a key insider, your
          feedback is important to us! <br />
          Please check out the event hub that we’ve created on Kiss & Tell where
          you’ll find details as we book, updates and a group chat where you can
          weigh in.
          <br />
          <br />
          As we plan, we’ll need your input to ensure that {
            data?.event?.name
          }{' '}
          is a huge success!
        </p>
        <div className="rsvp-actions">
          {isAuthenticated && (
            <Link className="text-center" to="/">
              <Button type="button">See event details & group chat</Button>
            </Link>
          )}
          {!isAuthenticated && (
            <Button type="button" onClick={() => openAuthModal(true)}>
              Sign in to access event hub
            </Button>
          )}
        </div>
      </Card>

      <SigninSignup
        open={authModal}
        onClose={() => openAuthModal(false)}
        preloadedData={preloadedData}
        redirectToHome
      />
    </>
  )
}
