import React, { useState, useContext, useEffect, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import { MdDone } from 'react-icons/md'
import { FaUserCircle } from 'react-icons/fa'
import { CgMenuLeftAlt } from 'react-icons/cg'
import { useLazyQuery } from '@apollo/react-hooks'
import { Link, useLocation, useHistory } from 'react-router-dom'

import AppContext from '../../context'
import Logo from '../../assets/images/logo.png'
import { MyEvent, Notification, authModalType } from '../../types'
import { GET_ALL_EVENTS } from '../../graphql'
import { salmon } from '../../styles/themeColors'
import { useCurrentUser } from '../../common'
import { SigninSignup } from '../../components'
import { myHub } from '../../common/constants'

import { MobileMenu, MobileMenuBody } from './style'
import { Submenu, Notifications } from './components'

interface IMobileNavbar {
  openHowItWorks: React.Dispatch<React.SetStateAction<boolean>>
  handleLogin?: (type?: authModalType) => void
  list?: Notification[]
}

export const MobileNavbar: React.FC<IMobileNavbar> = ({
  openHowItWorks,
  handleLogin,
  list,
}) => {
  const history = useHistory()
  const location = useLocation()
  const { isAuthenticated } = useCurrentUser()
  const [getMyEvents, { data }] = useLazyQuery(GET_ALL_EVENTS)

  const [openMenu, setOpenMenu] = useState(false)
  const [openLogin, setOpenLogin] = useState(false)
  const [expandedSubmenu, setExpandedSubmenu] = useState(false)
  const [openMenuUser, setOpenMenuUser] = useState(false)

  const { state, auth } = useContext(AppContext)
  const firstName = state?.currentUser?.firstName
  const lastName = state?.currentUser?.lastName
  const avatarUrl = state?.currentUser?.avatarUrl

  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isAuthenticated) {
      getMyEvents()
    }

    document.addEventListener('mousedown', ev => {
      if (!menuRef.current?.contains(ev.target as Node)) {
        setOpenMenuUser(false)
        setOpenMenu(false)
      }
    })
  }, [getMyEvents, isAuthenticated])

  const toggleMenu = () => {
    setOpenMenuUser(false)
    setOpenMenu(!openMenu)
  }
  const toggleMenuUser = () => {
    setOpenMenu(false)
    setOpenMenuUser(!openMenuUser)
  }

  const howItWorks = (ev: React.MouseEvent) => {
    ev.preventDefault()
    openHowItWorks(true)
    toggleMenu()
  }
  const handleLogout = (ev: React.MouseEvent) => {
    ev.preventDefault()
    auth.signOut()
    history.push('/')
  }

  const handleMyHub = (ev: React.MouseEvent) => {
    ev.preventDefault()
    toggleMenu()
    if (isAuthenticated) {
      history.push(myHub)
    } else {
      setOpenLogin(true)
    }
  }

  const myEvents: MyEvent[] = data?.me?.myEvents || []
  const activeEvent =
    myEvents.length > 0
      ? myEvents.find(ev => location.pathname.indexOf(`/event/${ev.id}`) === 0)
      : null

  return (
    <MobileMenu className="d-flex d-lg-none" ref={menuRef}>
      <SigninSignup open={openLogin} onClose={() => setOpenLogin(false)} />
      <div className="mr-auto">
        <CgMenuLeftAlt
          size={30}
          color={salmon}
          style={{ marginRight: isAuthenticated ? '44px' : '10px' }}
          onClick={toggleMenu}
        />
      </div>
      <Link to="/" className="mx-auto d-block">
        <img src={Logo} alt="Kiss and Tell" width="134" />
      </Link>
      <div className="ml-auto d-flex">
        {isAuthenticated && list && <Notifications list={list} />}
        <a onClick={toggleMenuUser}>
          {avatarUrl ? (
            <img
              src={avatarUrl}
              alt={`${firstName} ${lastName}`}
              style={{
                borderRadius: '50%',
                height: '30px',
                marginLeft: '1rem',
                width: '30px',
              }}
            />
          ) : (
            <FaUserCircle size={30} color={salmon} className="ml-3" />
          )}
        </a>
      </div>

      <MobileMenuBody
        className={`
        ${openMenu ? 'show' : ''}
        ${expandedSubmenu ? 'expandedSubmenu' : ''}
      `}
      >
        <Link onClick={handleMyHub} to="/">
          My Hub
        </Link>
        <Link onClick={toggleMenu} to="/blog">
          Inspiration
        </Link>
        <Link onClick={toggleMenu} to="/marketplace">
          Wedding Destinations
        </Link>
        <a onClick={howItWorks}>How We Work</a>

        {isAuthenticated && (
          <Submenu title="Manage Events" expandedSubmenu={setExpandedSubmenu}>
            {myEvents.map(event => (
              <Link
                key={event.id}
                to={`/event/${event.id}/settings`}
                onClick={toggleMenu}
              >
                {event.name}
                {activeEvent?.id === event.id && <MdDone />}
              </Link>
            ))}
          </Submenu>
        )}
      </MobileMenuBody>

      <MobileMenuBody className={openMenuUser ? 'show-user' : ''}>
        {isAuthenticated && (
          <>
            <Link onClick={toggleMenuUser} to="/manage-profile">
              Manage profile
            </Link>
            <a href="logout" onClick={handleLogout}>
              Logout
            </a>
          </>
        )}
        {!isAuthenticated && (
          <div className="pt-4 pb-2 mx-auto" style={{ maxWidth: '500px' }}>
            <Button
              onClick={() => handleLogin?.('register')}
              className="bradius-1 w-100"
              variant="outline-primary"
            >
              Register
            </Button>
            <p className="mt-3 mb-1 text-center w-100">
              already have an account?
            </p>
            <Button
              onClick={() => handleLogin?.('login')}
              className="w-100 bradius-1"
            >
              Login
            </Button>
          </div>
        )}
      </MobileMenuBody>
    </MobileMenu>
  )
}
