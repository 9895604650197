import React, { useState, useEffect, useCallback } from 'react'
import ReactGA from 'react-ga4'
import Modal from 'react-bootstrap/Modal'
import { MdCancel } from 'react-icons/md'
import { useHistory, useLocation } from 'react-router-dom'

import { Conversionly } from '../Iframes'

const ConversionlyModal: React.FC<{
  open: boolean
  onClose: () => void
  extraQueryParam?: Record<string, string>
}> = ({ open, onClose, extraQueryParam }) => {
  const history = useHistory()
  const location = useLocation()
  const [iframeLoaded, setIframeLoaded] = useState(false)

  const updateUrl = useCallback(() => {
    const searchParams = new URLSearchParams(location.search)
    let updated = false

    // variables
    const term = 'visited_location'
    const refUrl = 'ref_url'

    const source = 'utm_source'
    const sourceValue = 'FREE Consultation'

    const campaign = 'utm_campaign'
    const campaignValue = 'Conversionly Form'
    // end variables

    if (searchParams.get(source) !== sourceValue) {
      searchParams.set(source, sourceValue)
      updated = true
    }
    if (searchParams.get(campaign) !== campaignValue) {
      searchParams.set(campaign, campaignValue)
      updated = true
    }

    const url = `${window.location.origin}${location.pathname}`
    if (searchParams.get(refUrl) !== url) {
      searchParams.set(refUrl, url)
      updated = true
    }

    // verify if the user is visiting a provider page
    const regex = /^\/locations\/([a-z0-9-]+)$/
    const match = location.pathname.match(regex)
    if (match && searchParams.get(term) !== match[1]) {
      searchParams.set(term, match[1])
      updated = true
    }
    // end verify

    // add extra query params if they exist
    if (extraQueryParam) {
      Object.entries(extraQueryParam).forEach(([key, value]) => {
        if (searchParams.get(key) !== value) {
          searchParams.set(key, value)
          updated = true
        }
      })
    }
    // end extra query params

    // this is a validation to avoid infinite loop
    // when we use history.push it will trigger a re-render and its call again (in useEffect - updateUrl())
    if (updated) {
      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      })
    }
  }, [location.search, location.pathname, history, extraQueryParam])

  useEffect(() => {
    if (open) {
      updateUrl()

      ReactGA.event({
        category: 'Conversionly Form',
        label: 'Signup for FREE consultation',
        action: 'User opened the Start Planning modal - Conversionly',
      })

      if (!iframeLoaded) {
        const script = document.createElement('script')
        script.src = 'https://www.cnvrsnly.com/js/form_embed.js'
        script.async = true
        document.body.appendChild(script)
        setIframeLoaded(true)
      }
    }
  }, [open, iframeLoaded, updateUrl])

  return (
    <Modal
      size="lg"
      centered
      show={open}
      onHide={onClose}
      className="modal-rounded-0"
    >
      <MdCancel className="top-right cursor" onClick={onClose} size={20} />

      <Conversionly />
    </Modal>
  )
}

export default ConversionlyModal
