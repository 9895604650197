import React, { useState } from 'react'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import FormControl from 'react-bootstrap/FormControl'
import FormLabel from 'react-bootstrap/FormLabel'
import FormGroup from 'react-bootstrap/FormGroup'
import FormCheck from 'react-bootstrap/FormCheck'
import { v4 as uuidv4 } from 'uuid'
import { MdCancel } from 'react-icons/md'

import { red } from '../../../styles/themeColors'
import { offeringTypes } from '../../../common/constants'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { FormatNumber, ValidationModal } from '../../../components'
import {
  addPaidGuest,
  paidGuestsWasUpdated,
  removePaidGuest,
} from '../../../features/bookingSlice'
import {
  OfferingAddOns,
  OfferingAddOnOptions,
  Booking,
  BookingGuest,
  IBookingGuest,
} from '../../../types'

import { AddOnsSelectContainer } from './style'
import {
  getAddonFrequency,
  getPaidGuestOptions,
  getPaidGuestsLimit,
  paidGuestsGuideText,
} from './bookingFunctions'

export const PaidGuests: React.FC<{
  booking: Booking
  showPlusOne: boolean
}> = ({ booking, showPlusOne }) => {
  const dispatch = useAppDispatch()
  const {
    availableAddons: { extraGuestAddon, extraKidAddon },
    paidGuests = [],
  } = useAppSelector(state => state.booking)
  const [guestInfo, setGuestInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    plusone: false,
  })
  const [formNew, showFormNew] = useState(false)
  const [showValidationModal, setShowValidationModal] = useState(false)
  const [option, setOption] = useState<OfferingAddOnOptions>()
  const { guest: guestShort, eventComponent: ec } = booking
  const isRoom = ec.offering.__typename === offeringTypes.roomOffering
  const availablePlusOnes = guestShort.plusOne - guestShort.plusOneCount > 0

  const addons: { [s: string]: OfferingAddOns } = {}
  if (extraGuestAddon) addons[extraGuestAddon.id] = extraGuestAddon
  if (extraKidAddon) addons[extraKidAddon.id] = extraKidAddon

  const limit = getPaidGuestsLimit(ec)

  const handleAdd = (currentOption: OfferingAddOnOptions) => {
    setOption(currentOption)
    showFormNew(true)
    dispatch(paidGuestsWasUpdated(true))
  }

  const cancelAdd = () => {
    setOption(undefined)
    showFormNew(false)
    dispatch(paidGuestsWasUpdated(false))
  }

  const handleChange = (ev: React.ChangeEvent) => {
    const { value, name } = ev.currentTarget as HTMLInputElement
    setGuestInfo({
      ...guestInfo,
      [name]: value,
    })
  }

  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault()
    if (!option) return
    const type =
      option.offeringAddOnId === extraGuestAddon?.id ? 'adult' : 'kid'

    dispatch(
      addPaidGuest({
        id: uuidv4(),
        option,
        guest: guestInfo as BookingGuest,
        type,
      })
    )
    dispatch(paidGuestsWasUpdated(false))
    showFormNew(false)
    setGuestInfo({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      plusone: false,
    })
  }

  const handleDelete = (addon: IBookingGuest) => {
    dispatch(removePaidGuest(addon))
  }

  const combinedOptions = getPaidGuestOptions({
    extraGuestAddon,
    extraKidAddon,
    paidGuests,
  })

  const handleCheck = (plusone: boolean) => {
    if (isRoom && !availablePlusOnes) {
      setShowValidationModal(true)
      return
    }

    if (!isRoom && paidGuests.length >= limit) {
      setShowValidationModal(true)
      return
    }

    if (availablePlusOnes) {
      setGuestInfo({ ...guestInfo, plusone })
    }
  }

  return (
    <>
      <ValidationModal
        text="You have reached the maximum number of +1.<br />"
        textClass="fz-18 pt-2"
        show={showValidationModal}
        onHide={() => setShowValidationModal(false)}
      />
      <p className="fz-12 mb-2 tc-lgray">
        {paidGuestsGuideText({
          extraGuestAddon,
          extraKidAddon,
          component: ec,
          limit,
        })}
      </p>

      <Table
        borderless
        size="sm"
        data-cy="paid-users"
        className={paidGuests.length <= 0 ? 'd-none' : 'paid-guests-table'}
      >
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone</th>
            {showPlusOne && <th>+1</th>}
            <th>{/** actions */}</th>
          </tr>
        </thead>
        <tbody>
          {paidGuests.map(el => (
            <tr key={el.id}>
              <td>
                <p className="d-block d-md-none mb-1 fwsb">First Name</p>
                <FormControl value={el.guest.firstName} readOnly />
                <span
                  className="fz-11 tc-lgray d-inline-block position-relative"
                  style={{ top: '-4px', left: '1px' }}
                >
                  {`${el.option.description}`}
                </span>
              </td>
              <td>
                <p className="d-block d-md-none mb-1 fwsb">Last Name</p>
                <FormControl value={el.guest.lastName} readOnly />
              </td>
              <td>
                <p className="d-block d-md-none mb-1 fwsb">Email</p>
                <FormControl value={el.guest.email ?? ''} readOnly />
              </td>
              <td>
                <p className="d-block d-md-none mb-1 fwsb">Phone</p>
                <FormControl value={el.guest.phone ?? ''} readOnly />
              </td>
              {showPlusOne && (
                <td>
                  <span className="d-inline-block d-md-none fwsb pr-1">+1</span>
                  <FormCheck
                    className="mt-2"
                    checked={el.guest.plusone}
                    custom
                    type="checkbox"
                    id={`check-plusone-${el.id}`}
                    readOnly
                  />
                </td>
              )}
              <td>
                <MdCancel
                  size={20}
                  color={red}
                  title="Delete"
                  className="cursor"
                  style={{ margin: '8px 0 0 0' }}
                  onClick={() => handleDelete(el)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {formNew && (
        <form onSubmit={handleSubmit} data-cy="new-paid-user">
          <Table borderless size="sm" className="paid-guests-form-table">
            <tbody>
              <tr className="fz-15 fwsb border-bottom">
                <td className="pt-4 text-capitalize">{option?.label}</td>
                <td className="pt-4">
                  +<FormatNumber n={option?.price} />
                  {getAddonFrequency({
                    id: option?.offeringAddOnId,
                    isRoom,
                    addons,
                  })}
                </td>
                <td className="pt-4 text-right" colSpan={4}>
                  <Button
                    size="sm"
                    variant="outline-primary"
                    className="rounded-pill m-0"
                    onClick={cancelAdd}
                  >
                    cancel
                  </Button>
                </td>
              </tr>
              <tr>
                <td className="pt-3">
                  <FormGroup className="m-0">
                    <FormLabel>
                      First Name
                      <span className="text-danger"> *</span>
                    </FormLabel>
                    <FormControl
                      onChange={handleChange}
                      name="firstName"
                      value={guestInfo.firstName}
                      required
                    />
                  </FormGroup>
                </td>
                <td className="pt-3">
                  <FormGroup className="m-0">
                    <FormLabel>
                      Last Name <span className="text-danger"> *</span>
                    </FormLabel>
                    <FormControl
                      onChange={handleChange}
                      name="lastName"
                      value={guestInfo.lastName}
                      required
                    />
                  </FormGroup>
                </td>
                <td className="pt-3">
                  <FormGroup className="m-0">
                    <FormLabel>Email</FormLabel>
                    <FormControl
                      onChange={handleChange}
                      name="email"
                      value={guestInfo.email}
                      type="email"
                    />
                  </FormGroup>
                </td>
                <td className="pt-3">
                  <FormGroup className="m-0">
                    <FormLabel>Phone</FormLabel>
                    <FormControl
                      onChange={handleChange}
                      name="phone"
                      value={guestInfo.phone}
                    />
                  </FormGroup>
                </td>
                {showPlusOne && (
                  <td className="pt-3">
                    <FormGroup className="m-0">
                      <FormLabel>+1</FormLabel>
                      <FormCheck
                        className="mt-2"
                        checked={guestInfo.plusone}
                        onChange={() => handleCheck(!guestInfo.plusone)}
                        custom
                        type="checkbox"
                        id="check-plusone-new"
                      />
                    </FormGroup>
                  </td>
                )}
                <td className="align-bottom">
                  <Button
                    size="sm"
                    type="submit"
                    className="rounded-pill mb-1 px-4 px-md-2"
                  >
                    save
                  </Button>
                </td>
              </tr>
              <tr>
                <td className="text-danger fz-12 text-left pt-0" colSpan={6}>
                  * unsaved changes
                </td>
              </tr>
            </tbody>
          </Table>
        </form>
      )}

      {paidGuests.length < limit && (
        <AddOnsSelectContainer className="pt-2 pb-4" data-cy="addons-select">
          <Dropdown className="d-inline-block">
            <Dropdown.Toggle variant="outline-primary" id="add-addons-select">
              Add Guests
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {combinedOptions.map(el => {
                return (
                  <Dropdown.Item onClick={() => handleAdd(el)} key={el.id}>
                    Add {el.label} +<FormatNumber n={el.price} />
                    {getAddonFrequency({
                      id: el.offeringAddOnId,
                      isRoom,
                      addons,
                    })}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        </AddOnsSelectContainer>
      )}
    </>
  )
}
