import React, { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import getOr from 'lodash/fp/getOr'
import toLower from 'lodash/fp/toLower'
import Swal from 'sweetalert2'
import { useMutation } from '@apollo/react-hooks'
import { MdMailOutline, MdLockOutline } from 'react-icons/md'

import AppContext from '../../context'
import { useForm } from '../../common/alterForms'
import { simpleAlert, loaderAlert } from '../../common'
import { IgenericObject, ISignInUpPreloadedData } from '../../types'
import { KnTField } from '../../components'
import { CREATE_MARKETING_FORM } from '../../graphql'
import { gray } from '../../styles/themeColors'
import { callWebhook } from '../../common/webhooks'

import { useSource } from './components'

interface ISignin {
  onClose: () => void
  callback?: () => void
  marketing?: boolean
  preloadedData?: ISignInUpPreloadedData
}

export const Signin: React.FC<ISignin> = ({
  onClose,
  marketing = false,
  preloadedData,
  callback,
}) => {
  const { auth } = useContext(AppContext)
  const [errors, setErrors] = useState({ loginEmail: '' })
  const { getSource } = useSource()

  const initModel: IgenericObject = {
    loginEmail: getOr('', 'email', preloadedData),
    loginPassword: '',
  }
  const { model, handleFieldChange } = useForm(initModel)

  const [First100] = useMutation(CREATE_MARKETING_FORM)

  const login = async (ev: React.FormEvent) => {
    ev.preventDefault()
    const email = toLower(model.loginEmail)
    loaderAlert({ html: 'verifying credentials...' })
    const { data, error } = await auth.signIn({
      email,
      password: model.loginPassword,
    })
    if (error) {
      setErrors({ loginEmail: 'either password or email is incorrect.' })
      Swal.close()
    } else if (data) {
      onClose()
      Swal.close()
      if (marketing) {
        const first100Variables = {
          input: {
            form_name: 'first 100 login',
            form_data: JSON.stringify({ email }),
          },
        }
        First100({ variables: first100Variables })
          .then(({ data: First100Data }) => {
            if (First100Data.createMarketingForm.errors.length === 0) {
              simpleAlert({
                html: 'Help is on it’s way!  Your #1st100Groups Concierge will be in touch',
                icon: 'success',
              })
            }
          })
          .then(() => {
            callWebhook({
              hook: 'marketing',
              params: {
                data: JSON.stringify({
                  ...first100Variables,
                  source: getSource(),
                }),
              },
            })
          })
      }

      if (callback) callback() // optional callback
    }
  }

  return (
    <Form data-cy="signin-form" onSubmit={login}>
      <KnTField
        label="Email"
        value={model}
        error={errors}
        setValue={handleFieldChange}
        name="loginEmail"
        placeholder="your-email@example.com"
        type="email"
        icon={<MdMailOutline size={24} color={gray} />}
      />
      <KnTField
        label="Password"
        value={model}
        error={errors}
        setValue={handleFieldChange}
        name="loginPassword"
        placeholder="Enter your password"
        type="password"
        icon={<MdLockOutline size={24} color={gray} />}
      />
      <Button className="w-100 mt-4" type="submit">
        Login
      </Button>
    </Form>
  )
}
