import React, { useState, useEffect } from 'react'
import getOr from 'lodash/fp/getOr'
import toInteger from 'lodash/fp/toInteger'
import Row from 'react-bootstrap/Row'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Helmet } from 'react-helmet'
import { useQuery } from '@apollo/react-hooks'
import { MdOutlineExpandLess, MdOutlineExpandMore } from 'react-icons/md'

import { GET_LOCATIONS } from '../../graphql'
import { SimpleLoader, SwipeControl } from '../../components'
import {
  useMoveToTop,
  useQueryParams,
  getMoreData,
  processLocationParams,
  useCurrentUser,
} from '../../common'
import { Provider } from '../../types'

import ProviderListMap from './components/ProviderListMap'
import { ProvidersListConatiner, List } from './style'
import { LocationBanner, LocationCard } from './components'

const ProviderList: React.FC = () => {
  useMoveToTop()
  // scrollOnTop is a helper to mobile because the scroll change from the whole page to a specific container
  const scrollOnTop = window.innerWidth < 992 ? 'scrollableDiv' : ''

  const urlParams = useQueryParams()
  const [storedUrlParams, setStoredUrlParams] = useState('')
  const [googleMap, showGoogleMap] = useState(true)
  const [mobileList, openMobileList] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const [params, setParams] = useState<{ [s: string]: string | number }>({})
  const { isAuthenticated } = useCurrentUser()

  // in order to prevent many calls of the google API
  if (storedUrlParams !== JSON.stringify(urlParams)) {
    setStoredUrlParams(JSON.stringify(urlParams))

    processLocationParams(urlParams).then(response => {
      if (JSON.stringify(response) !== JSON.stringify(params)) {
        if (response.groupSize) {
          setParams({
            ...response,
            groupSize: toInteger(response.groupSize),
          })
        } else {
          setParams(response)
        }
      }
    })
  }
  // end

  const { data, loading, fetchMore } = useQuery(GET_LOCATIONS, {
    variables:
      urlParams?.lat && urlParams?.lng
        ? {
            ...params,
            where: `Point(${urlParams.lng},${urlParams.lat})`,
          }
        : params,
    fetchPolicy: 'cache-and-network',
  })
  const entries: Provider[] = getOr([], 'marketplace.entries', data)
  const cursorToken: string = getOr(null, 'marketplace.cursor', data)

  useEffect(() => {
    setHasMore(cursorToken !== null)
  }, [cursorToken])

  const fetchMoreData = () => {
    if (cursorToken) {
      getMoreData({
        fetchMore,
        query: GET_LOCATIONS,
        variables: params,
        cursorToken,
        path: 'marketplace',
      })
    }
  }

  const titleComplement = urlParams?.where || urlParams?.what
  return (
    <ProvidersListConatiner className={googleMap ? '' : 'container'}>
      <Helmet>
        <title>
          {titleComplement ? `${titleComplement} - ` : ''}
          Destination Wedding Locations | Kiss & Tell
        </title>
        <meta
          name="description"
          content="Find the perfect destination wedding location. Search by location, number of guests, and more."
        />
      </Helmet>
      <List
        className={`
          ${mobileList ? 'open-list' : ''}
          ${googleMap ? '' : 'w-100'}
          ${isAuthenticated ? 'logged-in' : ''}
        `}
        id={scrollOnTop}
      >
        <InfiniteScroll
          style={{ overflowX: 'hidden' }}
          dataLength={entries.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<SimpleLoader />}
          className={googleMap ? 'provider-list' : 'container-fluid'}
          scrollableTarget="scrollableDiv"
        >
          <SwipeControl
            state={mobileList}
            action={openMobileList}
            className="d-block d-lg-none"
          >
            {mobileList ? (
              <span>
                Close list view <MdOutlineExpandMore size={20} />
              </span>
            ) : (
              <span>
                List view <MdOutlineExpandLess size={20} />
              </span>
            )}
          </SwipeControl>
          <LocationBanner className="my-2 shadow-sm" small={googleMap} />
          {!googleMap && (
            <span
              className="text-primary cursor under text-right d-block pt-2 fz-14"
              onClick={ev => {
                ev.preventDefault()
                showGoogleMap(true)
              }}
            >
              show map
            </span>
          )}
          <Row className="rendered-cards">
            {entries.length === 0 && !loading && (
              <p className="w-100 text-center tc-lgray pt-5 fz-18 fwsb">
                There are no results for your search
              </p>
            )}
            {loading && <SimpleLoader />}
            {entries.map((el: Provider) => (
              <LocationCard
                groupSize={toInteger(params.groupSize)}
                key={el.id}
                data={el}
                size={googleMap ? { xs: 12, md: 6 } : undefined}
              />
            ))}
          </Row>
        </InfiniteScroll>
      </List>
      {googleMap && (
        <ProviderListMap
          providers={entries}
          urlParams={params}
          showGoogleMap={showGoogleMap}
        />
      )}
    </ProvidersListConatiner>
  )
}

export default ProviderList
