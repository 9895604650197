import React, { Suspense, lazy } from 'react'
import { Link } from 'react-router-dom'
import { IoMdCheckmarkCircle, IoIosHeart } from 'react-icons/io'

import equallywedHeader from '../../../assets/images/Landingpages/equallywed_header.jpg'
import { IframeYoutube } from '../../../common'
import { SmallLoader } from '../../../components'
import { pink, salmon } from '../../../styles/themeColors'
import {
  VerticalProgress,
  ExclusiveTheClearCut,
  PromoVideo,
  HeaderSection,
} from '../../TheClearCut/style'

import { Comparison } from './style'
import { TandCmodal } from './TandCmodal'

const LandingPageForm = lazy(() => import('./LandingPageForm'))

interface CommonProps {
  className?: string
}
export const Header: React.FC<CommonProps> = ({ className = '' }) => {
  return (
    <HeaderSection className={`equallywed-header ${className}`}>
      <h1
        className="text-center text-white font-lf align-self-center pb-md-3 p-md-0 pt-3 pt-md-0"
        style={{ fontSize: '100px' }}
      >
        You invite your tribe,
        <br />
        We create the vibe!
      </h1>
      <div className="text-center pt-4 pb-5 pb-md-0 w-60">
        <img
          src={equallywedHeader}
          alt="equallywedHeader"
          className="screenshotHeader"
        />
      </div>
    </HeaderSection>
  )
}

export const ComparisonSection: React.FC<CommonProps> = ({
  className = '',
}) => {
  return (
    <Comparison className={className}>
      <div className="problems">
        <dl>
          <dt>Problems Every Destination Wedding Faces:</dt>
          <dd>Narrowing-down nightmare</dd>
          <dd>Head-splitting logistics management and follow-up</dd>
          <dd>Unforeseen fees and penalties</dd>
        </dl>
      </div>
      <div className="what-you-get">
        <dl>
          <dt>What you get with us:</dt>
          <dd>
            Spend your time focusing on what’s most important and have peace of
            mind about the rest
          </dd>
          <dd>No number crunching</dd>
          <dd>No messy spreadsheets tracking guest bookings</dd>
        </dl>
      </div>
    </Comparison>
  )
}

export const FormSection: React.FC<
  CommonProps & { queryParams?: Record<string, string> }
> = ({ className = '', queryParams }) => {
  return (
    <section style={{ backgroundColor: pink }} className={className || 'pt-5'}>
      <Suspense fallback={<SmallLoader />}>
        <LandingPageForm
          title={
            <h2 className="text-center mt-0 mb-4 px-3">
              Start Planning Your Dream Wedding with Us <br />
              Gain Access to Event Credits or a Free Engagement Trip
            </h2>
          }
          queryParams={queryParams}
        />
      </Suspense>
    </section>
  )
}

export const CreditsSection: React.FC<
  CommonProps & { title: string; collage: string; collageClass?: string }
> = ({ className = '', title, collage, collageClass = '' }) => {
  return (
    <section className={`overflow-hidden ${className || 'pt-5 pb-3 pb-lg-5'}`}>
      <h2 className="text-center pb-4 fwl" style={{ fontSize: '40px' }}>
        {title}
      </h2>

      <ExclusiveTheClearCut>
        <div className={collageClass}>
          <img src={collage} alt="collage" className="mw-100" />
        </div>
        <div className="align-self-center px-3 px-sm-5 px-lg-4">
          <p className="fz-24 pb-3 fwsb">
            Get up to 5 nights fully reimbursed per following scale for a 3+
            night group*** stay for any property in Kiss & Tell marketplace.
          </p>
          <div className="fz-20 fwsb">
            <p className="my-1">
              <IoIosHeart color={salmon} className="mr-1" /> 1 night: 10+ rooms
              (20+ guests)
            </p>
            <p className="my-1">
              <IoIosHeart color={salmon} className="mr-1" /> 2 nights: 20+ rooms
              (40+ guests)
            </p>
            <p className="my-1">
              <IoIosHeart color={salmon} className="mr-1" /> 3 nights: 30+ rooms
              (60+ guests)
            </p>
            <p className="my-1">
              <IoIosHeart color={salmon} className="mr-1" /> 4 nights: 45+ rooms
              (90+ guests)
            </p>
            <p className="my-1">
              <IoIosHeart color={salmon} className="mr-1" /> 5 nights: 60+ rooms
              (120+ guests)
            </p>
          </div>
          <div className="d-none align-items-stretch">
            <VerticalProgress>
              <IoMdCheckmarkCircle color="white" size={40} />
              <span className="active">2</span>
              <span>3</span>
            </VerticalProgress>
            <div className="list pl-5 pr-2 fwsb">
              <p className="m-0">
                Book your discounted engagement trip
                <span className="pl-3 fwn font-italic d-block">
                  <span>*up to 5 nights</span>
                </span>
              </p>
              <p className="m-0 py-4">
                Pop the question without breaking the bank!
              </p>
              <p className="m-0">
                Reserve your big day and we’ll credit back your engagement trip
                stay!
              </p>
            </div>
          </div>
          <TandCmodal
            style={{ padding: '30px 0px 0px 0px', display: 'block' }}
          />
        </div>
      </ExclusiveTheClearCut>
    </section>
  )
}

export const VideoSection: React.FC<CommonProps> = ({ className = '' }) => {
  return (
    <div className={className || 'pt-0 pt-lg-5 pb-5 mt-0 mt-lg-4'}>
      <PromoVideo>
        <div className="px-3 px-sm-5 align-self-center pb-5 pb-lg-0">
          <h2 className="fwsb mb-4 mt-3 mt-md-0">
            When you’re ready to plan, we’ll get everyone there without the
            headache!
          </h2>
          <p className="m-0 fz-18">
            <Link to="/marketplace" className="fwsb">
              Explore our marketplace
            </Link>{' '}
            of top global destinations and seamlessly tie everything together
            with streamlined group planning, booking, communication and
            logistics for your big day!
          </p>
        </div>
        <div className="px-3 px-sm-5">
          <IframeYoutube videoId="jAJuzPinbPo" />
        </div>
      </PromoVideo>
    </div>
  )
}
