import styled from 'styled-components'

import { cardBorder, darkGray, grayBackground } from '../../styles/themeColors'

export const GridContainer = styled.div`
  width: 100%;
  .table-container {
    display: flex;
    position: relative;
    width: 100%;
    .custom-fixed-grid,
    .custom-normal-grid {
      flex: 1;
      overflow-x: scroll;
      position: relative;
      scroll-behavior: smooth;
      scrollbar-width: thin; /* Firefox */
      will-change: scroll-position;
      &::-webkit-scrollbar {
        height: 10px; /* Scrollbar height */
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    .custom-fixed-grid {
      scrollbar-color: transparent transparent !important; /* Firefox */
      &::-webkit-scrollbar-thumb {
        background: transparent !important; /* Thumb color */
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: transparent !important; /* Thumb color on hover */
      }
      &::-webkit-scrollbar-track {
        background: transparent !important; /* Track background */
      }
      .custom-grid {
        tr {
          th,
          td {
            &:first-child {
              border-left: none;
            }
          }
        }
      }
    }
    .custom-normal-grid {
      min-width: 70%;
      scrollbar-color: #888 transparent; /* Firefox */
      &::-webkit-scrollbar-thumb {
        background: #888; /* Thumb color */
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Thumb color on hover */
      }
      &::-webkit-scrollbar-track {
        background: transparent; /* Track background */
      }
      .custom-grid {
        tr {
          th,
          td {
            &:first-child {
              border-left: none;
            }
            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }
  }
  .search-input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 5px 0 5px 3px;
    max-width: 250px;
    position: relative;
    width: 100%;
    input {
      border: none;
      border-radius: 10px;
      padding: 8px 30px 8px 10px;
      width: 100%;
      &:focus {
        outline: none;
      }
    }
    svg {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .items-per-page {
    padding: 0 10px;
    select {
      margin: 0 0 0 5px;
    }
  }
  .grid-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .grid-footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 20px 10px;
    width: 100%;
    * {
      user-select: none;
    }
    .grid-pagination {
      button {
        background-color: white;
        border: 1px solid ${cardBorder};
        border-left: none;
        padding: 5px 12px;
        &:first-child {
          border-radius: 5px 0 0 5px;
          border-left: 1px solid ${cardBorder};
        }
        &:last-child {
          border-radius: 0 5px 5px 0;
        }
        &:not(:disabled):hover {
          background-color: ${grayBackground};
          cursor: pointer;
        }
      }
    }
    .grid-results {
      span {
        font-weight: 700;
        padding: 0 4px;
      }
    }
  }
  .custom-grid {
    height: 100%;
    width: 100%;
    tr {
      th,
      td {
        border: 1px solid ${cardBorder};
        height: 48px;
        max-height: 48px;
      }
      th {
        padding: 12px;
        position: relative;
        user-select: none;
        svg {
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
          &:hover {
            cursor: pointer;
          }
        }
      }
      td {
        padding: 8px 12px;
        &.row-number {
          background-color: ${grayBackground};
          color: ${darkGray};
          padding: 10px;
          text-align: center;
          width: 20px;
        }
        & .load-bookings {
          background-color: transparent;
          border: none;
          padding: 0;
        }
      }
    }
    thead {
      background-color: ${grayBackground};
      color: ${darkGray};
    }
  }
  // custom styles - if we have multiple styles we could move this to a separate scss or sass file
  .plus-one {
    td {
      &.name-column {
        padding-left: 40px !important;
      }
      &.rooms-column,
      &.flight-column,
      &.events-column {
        text-align: center !important;
      }
      & .load-bookings {
        position: relative;
        &.plus-one-icon::after {
          border-radius: 50%;
          color: ${darkGray};
          content: '+1';
          font-size: 10px;
          position: absolute;
          right: -5px;
          top: -5px;
        }
      }
    }
  }
  // end custom styles
  @media (max-width: 1200px) {
    .table-container {
      .custom-grid {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  @media (max-width: 768px) {
    .grid-footer {
      flex-direction: column;
      .items-per-page {
        order: 1;
        margin: 5px 0 10px 0;
      }
      .grid-pagination {
        margin-bottom: 15px;
        order: 2;
      }
      .grid-results {
        margin: 0;
        order: 3;
      }
    }
    .custom-fixed-grid {
      width: 25% !important;
    }
    .custom-normal-grid {
      min-width: 75% !important;
      width: 75% !important;
    }
    .custom-grid {
      tr {
        td {
          height: 60px;
          max-height: 60px;
          &.row-number {
            padding: 10px 0px;
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    .grid-header {
      margin: 0 0 10px 0;
      .items-per-page {
        width: 30%;
        select {
          display: block;
          margin: -7px 0 0 0;
          width: 100%;
        }
      }
    }
    .search-input {
      margin: 10px auto 0 auto;
      max-width: 65%;
      width: 65%;
    }
    .custom-fixed-grid {
      width: 40% !important;
    }
    .custom-normal-grid {
      min-width: 60% !important;
      width: 60% !important;
    }
  }
  @media (max-width: 420px) {
    .grid-header {
      .items-per-page {
        width: 40%;
        select {
          margin: -5px 0 0 0;
        }
      }
      .search-input {
        max-width: 56%;
        width: 56%;
      }
    }
  }
`
