import React from 'react'
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

import { EventIcon } from '../../../../components/customIcons'
import { EventComponent, Guest } from '../../../../types'
import { CREATE_BOOKING_FOR_GUEST } from '../../../../graphql'
import { errorAlert, loaderAlert } from '../../../../common'
import { useAppDispatch } from '../../../../hooks'
import { setOnBehalfOfGuest } from '../../../../features/bookingSlice'

export const BookingForGuest: React.FC<{
  eventComponent: EventComponent
  guest: Guest
  className?: string
  rsvpText?: string
}> = ({
  guest,
  eventComponent,
  className = 'px-3 py-1',
  rsvpText = 'RSVP for guest',
}) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [createBooking, { loading }] = useMutation(CREATE_BOOKING_FOR_GUEST)

  const handleCreateBooking = (ev: React.MouseEvent) => {
    ev.preventDefault()
    loaderAlert({ html: 'processing booking' })

    const variables = {
      eventComponentId: eventComponent.id,
      guestId: guest.id,
    }
    createBooking({ variables })
      .then(({ data }) => {
        Swal.close()
        const errors = data?.createBookingForGuest?.errors || []
        const result = data?.createBookingForGuest?.result

        if (errors.length > 0) {
          errorAlert(errors, 'There was an error while booking')
        } else {
          dispatch(setOnBehalfOfGuest(guest))
          history.push(`/booking/${result?.id}`)
        }
      })
      .catch(error => errorAlert([], error.message))
  }

  return (
    <Button
      className={className}
      onClick={handleCreateBooking}
      disabled={loading}
    >
      <EventIcon color="white" width="25px" height="25px" />
      {rsvpText}
    </Button>
  )
}
