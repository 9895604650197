import React from 'react'
import Container from 'react-bootstrap/Container'

import { Conversionly } from '../components/Iframes'

export const SignupPage: React.FC = () => {
  return (
    <Container className="mt-2 mt-md-5">
      <Conversionly />
    </Container>
  )
}
