import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { MdCancel } from 'react-icons/md'
import Modal from 'react-bootstrap/Modal'

import { myHub } from '../../common/constants'
import { ISignInUpPreloadedData } from '../../types'

import { Signin } from './Signin'
import { SignInUpContainer } from './style'
import { SignInOutTabs } from './components'

export interface ISigninSignup {
  open: boolean
  onClose: () => void
  preloadedData?: ISignInUpPreloadedData
  topText?: string
  redirectToHome?: boolean
}

const topTextComp = (text: string) => (
  <p className="w-75 mx-auto text-center fz-13 tc-lgray">{text}</p>
)

export const SigninSignup: React.FC<ISigninSignup> = ({
  open,
  onClose,
  preloadedData,
  topText,
  redirectToHome,
}) => {
  const history = useHistory()

  const close = () => {
    onClose()
    if (redirectToHome) {
      history.push(myHub)
    }
  }
  return (
    <Modal show={open} onHide={onClose} className="modal-rounded-0">
      <SignInUpContainer>
        <MdCancel size={20} className="closeModal cursor" onClick={onClose} />
        <SignInOutTabs
          loginChildren={
            <div className="px-3 px-lg-5">
              {topText && topTextComp(topText)}
              <Signin onClose={close} preloadedData={preloadedData} />
              <Link
                to="/reset-password"
                className="w-100 d-block text-center fz-14 my-4"
                onClick={onClose}
              >
                Forgot password?
              </Link>
            </div>
          }
        />
      </SignInUpContainer>
    </Modal>
  )
}
