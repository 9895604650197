import React from 'react'
import map from 'lodash/fp/map'
import some from 'lodash/fp/some'
import flow from 'lodash/fp/flow'
import every from 'lodash/fp/every'
import filter from 'lodash/fp/filter'
import toLower from 'lodash/fp/toLower'
import orderBy from 'lodash/fp/orderBy'

import {
  Booking,
  BookingStatus,
  EventComponent,
  PackageOffering,
  RoomOffering,
} from '../../../../types'
import {
  GridRow,
  GridField,
  SortConfig,
} from '../../../../components/Grid/types'
import { orderPackageAddons } from '../../../../common'

type GridData = (GridRow | GridRow[])[]

export const orderData = (
  data: GridData,
  sortConfig?: SortConfig
): GridData => {
  if (!sortConfig) return data
  const { key, direction } = sortConfig

  return orderBy(
    (row: GridRow | GridRow[]) => {
      let resp: GridField
      const isArray = Array.isArray(row) // if it's an array means that the guest has plus ones

      /**
       * Custom sort function for RSVP only
       * we are using the data-position attribute to sort the column
       * this property is added in the function getIcon in hasOfferingBooked
       * in src\pages\Event\GuestList\components\GuestsGrid.tsx - line 104
       * */
      if (key === 'events') {
        const getPosition = (r: GridRow): number => {
          const btn = r[key] as React.ReactElement
          return btn?.props?.['data-position'] ?? 99 // to put elements without data-position at the end
        }
        if (isArray) return getPosition(row[0])
        return getPosition(row)
      }
      /* end of custom sort function for RSVP */

      if (isArray) {
        row.some((item: GridRow) => {
          const val = item[key]
          if (resp) return true

          resp = typeof val === 'string' ? toLower(val) : val
          return false
        })
        return resp
      }

      const val = row[key]
      return typeof val === 'string' ? toLower(val) : val
    },
    direction,
    data
  )
}

export const searchData = (data: GridData, search?: string): GridData => {
  if (!search) return data

  return data?.filter((row: GridRow | GridRow[]) => {
    // right now we are only searching for the name and status
    const searchValue = toLower(search)

    if (Array.isArray(row)) {
      return row.some((item: GridRow) => {
        const name = toLower(item?.name as string)
        const status = toLower(item?.status as string)
        return name.includes(searchValue) || status.includes(searchValue)
      })
    }
    const name = toLower(row?.name as string)
    const status = toLower(row?.status as string)
    return name.includes(searchValue) || status.includes(searchValue)
  })
}

/**
 * Filter the data by different filters. Right now we are only filtering by event components (packages)
 * @param data: GridRow[] - the data to filter
 * @param packages: { byPackage: string[], byStatus: string[] } - the filters for packages - both are required
 * @param rooms: string[] - the filters for rooms
 * @param type: PackageOffering['__typename'] | RoomOffering['__typename']
 * @returns GridRow[] - the filtered data
 */
export const filterData = ({
  data,
  packages,
  rooms = [],
  type,
}: {
  // packages: { byPackage: filterPackages, byStatus: filterByEventStatus },
  data: GridRow[]
  packages?: {
    byPackage: string[]
    byStatus: string[]
  }
  rooms?: string[]
  type: PackageOffering['__typename'] | RoomOffering['__typename']
}): GridRow[] => {
  if (!type) return data

  const getAttr = (r: GridRow, attr: string): string => {
    const btn = r.events as React.ReactElement
    return btn?.props?.[attr]
  }

  if (type === 'RoomOffering') {
    // right now there is no filter for RoomOffering so this is just to leave the structure
    // in case we need to add a filter for rooms in the future
    if (rooms?.length <= 0) return data
    return data
  }

  // type === 'PackageOffering'
  if (!packages) return data
  const { byPackage, byStatus } = packages
  if (byPackage.length <= 0 && byStatus.length <= 0) return data // both filters are required

  return filter(row => {
    const bookings: {
      component: string
      status: string
    }[] = JSON.parse(getAttr(row, 'data-bookings') || '[]')

    return every(packageId => {
      const matchingBookingsByEC = filter(
        b => b.component === packageId,
        bookings
      )
      if (matchingBookingsByEC.length === 0) return false

      if (byStatus.length > 0) {
        return some(
          booking => byStatus.includes(booking.status),
          matchingBookingsByEC
        )
      }
      return true
    }, byPackage)
  }, data)
}

export const bookingStatusAux = (
  booking: Booking,
  className = ''
): React.ReactElement => {
  const { Declined, Cancelled, Booked } = BookingStatus
  const { status } = booking
  if (status === Declined || status === Cancelled) {
    return <span className={className || 'd-block pt-2'}>Declined</span>
  }
  if (status === Booked) {
    return <span className={className || 'd-block'}>Attending</span>
  }
  return <span />
}

/**
 * Sort the packages by event component date and the first addon time
 * @param components EventComponent[]
 * @returns EventComponent[]
 */
export const sortPackages = (
  components: EventComponent[] = []
): EventComponent[] => {
  if (components.length <= 1) return components

  // order first by time and then by the first addon time
  return flow(
    map((el: EventComponent) => ({
      ...el,
      packageCustomization: orderPackageAddons(el.packageCustomization),
      parsedDate: new Date(el.date), // there were some issues with the date format so we are parsing it here
    })),
    orderBy(['parsedDate', 'packageCustomization[0].time'], ['asc', 'asc'])
  )(components) as EventComponent[]
}
