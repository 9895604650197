import React, { useReducer, Suspense, useMemo } from 'react'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'

import { createApolloClient } from './apollo-client'
import { PublicHome, HowWeWork, LoginPage } from './pages/Home'
import { About } from './pages/About'
import { Partnership, Landing } from './pages/Partnership'
import { Terms } from './pages/Terms'
import { Privacy } from './pages/Privacy'
import { ResetPassword, SetPassword, Verify } from './pages/User'
import { Locations } from './pages/Locations'
import { Blog } from './pages/Blog'
import { Referrals } from './pages/Referrals'
import { ChatWithVendor } from './pages/Event/ChatWithVendor'
import { VendorSignin } from './components/SigninSignup'
import { ManageProfile } from './pages/ManageProfile'
import { MyOfferingsChats } from './pages/VendorPanel'
import { Panel } from './pages/Panel'
import { Notifications } from './pages/Notifications'
import { Booking } from './pages/Booking'
import { Event } from './pages/Event'
import { SignupPage } from './pages/SignupPage'
import { TheClearCut } from './pages/TheClearCut'
import { Emails } from './pages/Emails'
import { RedirectAutoLogin, Redirect as Redirection } from './pages/Redirect'
import Layout from './components/Layout'
import AppContext from './context'
import reducer, { getInitialState } from './reducer'
import { OnlyPublicRoute, PrivateRoute } from './auth/authRoutes'
import useAuthentication from './auth/auth'
import {
  RSVPpreview,
  InternalUseInvitation,
  ExternalUseInvitation,
  ComponentsSelection,
} from './pages/Rsvp'
import {
  SaraAndJean,
  EquallyWed,
  EngagementTrip,
  LauraAndKevin,
} from './pages/Landingpages'
import { InvitePage } from './pages/UnauthenticatedFlow'

const App: React.FC = () => {
  const trackingId = 'G-PYKBVN7KWV'
  ReactGA.initialize(trackingId, { testMode: false })

  const [state, dispatch] = useReducer(reducer, getInitialState())
  const auth = useAuthentication({ state, dispatch })
  const ctxConfig = useMemo(() => ({ state, dispatch, auth }), [auth, state])

  return (
    <div data-test="component-app">
      <Helmet>
        <title>Kiss & Tell | Destination Weddings Made Simple</title>
        <meta
          name="description"
          content="Kiss & Tell is a destination wedding planning platform where you can manage your guest lists, book hotel room blocks, and much more."
        />
      </Helmet>

      <BrowserRouter>
        <AppContext.Provider value={ctxConfig}>
          <ApolloProvider client={createApolloClient(auth)}>
            <Layout>
              <Suspense fallback={<div>Loading....</div>}>
                <Switch>
                  <Route exact path="/" component={PublicHome} />
                  <Route exact path="/hub" component={Panel} />
                  <Route exact path="/how-we-work" component={HowWeWork} />
                  <OnlyPublicRoute exact path="/feed" component={LoginPage} />
                  <Route exact path="/about-us" component={About} />
                  <Route exact path="/referrals" component={Referrals} />
                  <Route exact path="/partnerships" component={Partnership} />
                  <Route exact path="/theclearcut" component={TheClearCut} />
                  <PrivateRoute
                    exact
                    path="/room-selection/:eventId/:providerId"
                    component={ComponentsSelection}
                  />
                  <PrivateRoute
                    exact
                    path="/events-list/:eventId/:providerId"
                    component={ComponentsSelection}
                  />
                  <Route
                    exact
                    path="/engagement-trip"
                    component={EngagementTrip}
                  />
                  <Route
                    exact
                    path="/engagement-trip-deals"
                    component={Landing}
                  />
                  <Route
                    exact
                    path="/saraandjeanpaul"
                    component={SaraAndJean}
                  />
                  <Route
                    exact
                    path="/laura-and-kevin"
                    component={LauraAndKevin}
                  />
                  <Route exact path="/equallywed" component={EquallyWed} />
                  <Route exact path="/terms-of-use" component={Terms} />
                  <Route exact path="/privacy-policy" component={Privacy} />
                  <Route path="/marketplace" component={Locations} />
                  <Route path="/locations" component={Locations} />
                  <Route path="/blog" component={Blog} />
                  <Route
                    path="/guest/:token"
                    component={ExternalUseInvitation}
                  />
                  <Route path="/redirect/:token" component={Redirection} />
                  <Route
                    exact
                    path="/redirect-guest/:token/:event"
                    component={RedirectAutoLogin}
                  />
                  <Route
                    exact
                    path="/invitation/:id"
                    component={InternalUseInvitation}
                  />
                  <Route path="/invite/:customUrl" component={InvitePage} />
                  <PrivateRoute
                    path="/my-offerings-chats"
                    component={MyOfferingsChats}
                  />
                  <PrivateRoute path="/event/:eventId" component={Event} />
                  <PrivateRoute path="/emails/:eventId" component={Emails} />
                  <PrivateRoute
                    path="/notifications"
                    component={Notifications}
                  />
                  <PrivateRoute
                    path="/rsvp-preview/:eventId"
                    component={RSVPpreview}
                  />
                  <PrivateRoute
                    path="/planning/:eventId/:providerId"
                    component={ChatWithVendor}
                  />
                  <PrivateRoute
                    path="/booking/:bookingId"
                    component={Booking}
                  />
                  <PrivateRoute
                    path="/manage-profile"
                    component={ManageProfile}
                  />
                  <OnlyPublicRoute
                    exact
                    path="/signup"
                    component={SignupPage}
                  />
                  <OnlyPublicRoute
                    exact
                    path="/vendor-login"
                    component={VendorSignin}
                  />
                  <Route
                    exact
                    path="/reset-password/:token?"
                    component={ResetPassword}
                  />
                  <Route
                    exact
                    path="/verify/:token/:event?"
                    component={Verify}
                  />
                  <Route
                    exact
                    path="/set-password/:token/:event?"
                    component={SetPassword}
                  />
                  <Route path="*">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </Suspense>
            </Layout>
          </ApolloProvider>
        </AppContext.Provider>
      </BrowserRouter>
    </div>
  )
}

export default App
