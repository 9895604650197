/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  Guest,
  ICategorizedAddons,
  OfferingAddOns,
  OfferingAddOnOptions,
  IBookingGuest,
  IFreeBookingGuest,
  OfferingAddOnOptionsWithType,
  IcurrentAddons,
  BookingGuest,
} from '../types'
import { categorizeOfferingAddons } from '../pages/Booking/components/bookingFunctions'
import { addonMetadata } from '../common/constants'

import {
  // setExtraNights,
  cleanupStorage,
  getBookingId,
  setBookingId,
  setExtraNights,
  getExtraNights,
  setFreeGuests,
  getFreeGuests,
  setPaidGuests,
  getPaidGuests,
  resetAllAddons,
  setOnBehalf,
  getOnBehalf,
} from './utils'

const initialState: {
  totalPrice?: number
  currentBookingId?: string
  renderHelper: number
  availableAddons: ICategorizedAddons
  customBookingDate: string | null
  customBookingDuration: number | null
  // extra nights
  extraNights?: OfferingAddOnOptionsWithType[]
  extraNightsUpdated?: boolean
  // free guests
  freeGuests?: IFreeBookingGuest[]
  freeGuestsUpdated?: boolean
  // paid guests
  paidGuests?: IBookingGuest[]
  paidGuestsUpdated?: boolean
  // list to delete
  addonsIdsToDelete?: string[]
  // helper to know if the host is booking on behalf of a guest
  onBehalfOfGuest?: Guest
} = {
  renderHelper: 0,
  totalPrice: 0,
  currentBookingId: getBookingId(),
  availableAddons: {},
  extraNights: getExtraNights(),
  freeGuests: getFreeGuests(),
  paidGuests: getPaidGuests(),
  customBookingDate: null,
  customBookingDuration: null,
  addonsIdsToDelete: [],
  onBehalfOfGuest: getOnBehalf(),
}

const bookingSlice = createSlice({
  name: 'booking_slice',
  initialState,
  reducers: {
    setOnBehalfOfGuest: (state, action: PayloadAction<Guest>) => {
      state.onBehalfOfGuest = action.payload
      setOnBehalf(action.payload)
    },
    setTotalPrice: (state, action: PayloadAction<number>) => {
      state.totalPrice = action.payload
    },
    setAvailableAddons: (
      state,
      action: PayloadAction<{ addons?: OfferingAddOns[]; bookingId: string }>
    ) => {
      state.availableAddons = categorizeOfferingAddons(
        action.payload.addons ?? []
      )

      if (action.payload.bookingId !== state.currentBookingId) {
        state.currentBookingId = action.payload.bookingId
        setBookingId(action.payload.bookingId)
        // reset addons
        state.extraNights = []
        state.freeGuests = []
        state.paidGuests = []
        resetAllAddons()
      }
    },
    setCurrentAddons: (state, action: PayloadAction<IcurrentAddons>) => {
      const currentAddons = action.payload

      // prepopulate extra nights with current booking addons
      const extraNights: OfferingAddOnOptionsWithType[] = []
      if (currentAddons.extraNightsBefore) {
        const option = currentAddons.extraNightsBefore.offeringAddOnOption
        extraNights.push({
          ...(option as OfferingAddOnOptions),
          type: 'before',
          bookingAddOnId: currentAddons.extraNightsBefore.id,
        })
      }
      if (currentAddons.extraNightsAfter) {
        const option = currentAddons.extraNightsAfter.offeringAddOnOption
        extraNights.push({
          ...(option as OfferingAddOnOptions),
          type: 'after',
          bookingAddOnId: currentAddons.extraNightsAfter.id,
        })
      }
      state.extraNights = extraNights
      setExtraNights(extraNights)

      // prepopulate free guests with current booking addons
      if (currentAddons.freeGuests) {
        state.freeGuests = currentAddons.freeGuests.map(el => ({
          id: el.id,
          addon: el.offeringAddOn,
          guest: {
            firstName: el.bookingGuest?.firstName,
            lastName: el.bookingGuest?.lastName,
            email: el.bookingGuest?.email,
            phone: el.bookingGuest?.phone,
            plusone: el.bookingGuest?.plusone ?? false,
          } as BookingGuest,
          bookingAddOnId: el.id,
        }))
        setFreeGuests(state.freeGuests)
      }

      // prepopulate paid guests with current booking addons
      if (currentAddons.paidGuests) {
        state.paidGuests = currentAddons.paidGuests.map(el => ({
          id: el.id,
          option: el.offeringAddOnOption as OfferingAddOnOptions,
          guest: {
            firstName: el.bookingGuest?.firstName,
            lastName: el.bookingGuest?.lastName,
            email: el.bookingGuest?.email,
            phone: el.bookingGuest?.phone,
            plusone: el.bookingGuest?.plusone ?? false,
          } as BookingGuest,
          bookingAddOnId: el.id,
          type:
            el.offeringAddOn.metadata.subtype === addonMetadata.guest
              ? 'adult'
              : 'kid',
        }))
        setPaidGuests(state.paidGuests)
      }

      state.renderHelper += 1
    },
    // extra nights
    addExtraNight: (
      state,
      action: PayloadAction<{
        option?: OfferingAddOnOptions
        existing?: string
        type: 'before' | 'after'
      }>
    ) => {
      // remove existing extra night by OfferingAddOnId
      if (action.payload.existing) {
        state.extraNights = state.extraNights?.filter(addon => {
          if (addon?.bookingAddOnId) {
            state.addonsIdsToDelete?.push(addon.bookingAddOnId)
          }
          return addon.offeringAddOnId !== action.payload.existing
        })
      }

      if (action.payload.option) {
        state.extraNights?.push({
          ...action.payload.option,
          type: action.payload.type,
        })
        setExtraNights(state.extraNights)
      }
    },
    extraNightWasUpdated: (state, action: PayloadAction<boolean>) => {
      state.extraNightsUpdated = action.payload
    },
    removeExtraNight: (
      state,
      action: PayloadAction<{
        option: OfferingAddOnOptions
        bookingAddOnId?: string
      }>
    ) => {
      state.extraNights = state.extraNights?.filter(
        addon => addon.id !== action.payload.option.id
      )
      if (action.payload.bookingAddOnId) {
        state.addonsIdsToDelete?.push(action.payload.bookingAddOnId)
      }
      setExtraNights(state.extraNights)
    },
    setCustomBookingRange: (
      state,
      action: PayloadAction<{ date: string | null; duration: number | null }>
    ) => {
      state.customBookingDate = action.payload.date
      state.customBookingDuration = action.payload.duration
    },
    // free guests
    addFreeGuest: (state, action: PayloadAction<IFreeBookingGuest>) => {
      state.freeGuests?.push(action.payload)
      setFreeGuests(state.freeGuests)
    },
    editFreeGuest: (state, action: PayloadAction<IFreeBookingGuest>) => {
      state.freeGuests = state.freeGuests?.map(guest => {
        if (guest.id === action.payload.id) {
          return action.payload
        }
        return guest
      })
      setFreeGuests(state.freeGuests)
    },
    freeGuestsWasUpdated: (state, action: PayloadAction<boolean>) => {
      state.freeGuestsUpdated = action.payload
    },
    removeFreeGuest: (state, action: PayloadAction<string>) => {
      state.freeGuests = state.freeGuests?.filter(addon => {
        if (addon.bookingAddOnId === action.payload) {
          state.addonsIdsToDelete?.push(action.payload)
        }
        return addon.id !== action.payload
      })
      setFreeGuests(state.freeGuests)
    },
    // paid guests
    addPaidGuest: (state, action: PayloadAction<IBookingGuest>) => {
      state.paidGuests?.push(action.payload)
      setPaidGuests(state.paidGuests)
    },
    paidGuestsWasUpdated: (state, action: PayloadAction<boolean>) => {
      state.paidGuestsUpdated = action.payload
    },
    removePaidGuest: (state, action: PayloadAction<IBookingGuest>) => {
      state.paidGuests = state.paidGuests?.filter(addon => {
        if (addon.bookingAddOnId === action.payload.bookingAddOnId) {
          state.addonsIdsToDelete?.push(action.payload.bookingAddOnId as string)
        }
        return addon.id !== action.payload.id
      })
      setPaidGuests(state.paidGuests)
    },
    cleanAddonIdsToDelete: state => {
      state.addonsIdsToDelete = []
    },
    cleanBooking: () => {
      cleanupStorage()
      return {
        availableAddons: {},
        customBookingDate: null,
        customBookingDuration: null,
        renderHelper: 0,
        onBehalfOfGuest: undefined,
      }
    },
  },
})

export const {
  cleanBooking,
  cleanAddonIdsToDelete,
  setTotalPrice,
  setAvailableAddons,
  setCurrentAddons,
  // extra nights
  addExtraNight,
  extraNightWasUpdated,
  removeExtraNight,
  setCustomBookingRange,
  // free guests
  addFreeGuest,
  editFreeGuest,
  freeGuestsWasUpdated,
  removeFreeGuest,
  // paid guests
  addPaidGuest,
  paidGuestsWasUpdated,
  removePaidGuest,
  setOnBehalfOfGuest,
} = bookingSlice.actions
export default bookingSlice.reducer
