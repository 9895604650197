import React from 'react'
import find from 'lodash/fp/find'
import uniq from 'lodash/fp/uniq'
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button'
import { useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

import { useAppSelector } from '../../../hooks'
import { FormatNumber } from '../../../components'
import { checkDuplicatedGuests } from '../../Rsvp/components'
import { IGuestPayment, Booking, BookingStatus } from '../../../types'
import {
  confirmAlert,
  errorAlert,
  loaderAlert,
  simpleAlert,
} from '../../../common'
import {
  ADD_ALL_ADDONS_TO_BOOKING,
  CONFIRM_BOOKING,
  DECLINE_BOOKING,
} from '../../../graphql'

export const GuestPayment: React.FC<IGuestPayment> = ({
  guestPrice,
  booking,
  disabled = false,
  paymentText = 'Pay',
}) => {
  const history = useHistory()
  const {
    freeGuests = [],
    paidGuests = [],
    extraNights = [],
    addonsIdsToDelete = [],
    customBookingDate,
    customBookingDuration,
  } = useAppSelector(state => state.booking)
  const [confirmBooking, { loading }] = useMutation(CONFIRM_BOOKING)
  const [payBooking, { loading: paying }] = useMutation(
    ADD_ALL_ADDONS_TO_BOOKING
  )
  const { id: bookingId } = booking

  const confirm = (ev: React.MouseEvent) => {
    ev.preventDefault()
    if (checkDuplicatedGuests([...freeGuests, ...paidGuests])) {
      simpleAlert({
        html: 'There cannot be duplicated guests',
        icon: 'warning',
      })
      return
    }

    if (loading) return
    loaderAlert({ html: 'confirming...' })

    const before = find({ type: 'before' }, extraNights)
    const after = find({ type: 'after' }, extraNights)
    const variables = {
      bookingId,
      freeGuests: freeGuests.map(fg => ({
        bookingId,
        guestBookingInput: { ...fg.guest },
        offeringAddOnId: fg.addon.id,
        bookingAddOnId: fg.bookingAddOnId,
      })),
      paidGuests: paidGuests.map(pg => ({
        bookingId,
        guestBookingInput: { ...pg.guest },
        offeringAddOnId: pg.option.offeringAddOnId,
        offeringAddOnOptionId: pg.option.id,
        bookingAddOnId: pg.bookingAddOnId,
      })),
      extraNights: {
        bookingId,
        date: customBookingDate,
        duration: customBookingDuration,
        addonNightsAfterId: after?.offeringAddOnId,
        optionNightsAfterId: after?.id,
        addonNightsBeforeId: before?.offeringAddOnId,
        optionNightsBeforeId: before?.id,
      },
      addonsToDelete: uniq(addonsIdsToDelete),
    }
    confirmBooking({ variables })
      .then(({ data }) => {
        const pay = data?.confirmBooking
        Swal.close()

        if (pay?.errors.length > 0) {
          errorAlert(
            pay?.errors,
            'there has been an error confirming your reservation'
          )
        } else {
          history.push(`/booking/${bookingId}/details`)
        }
      })
      .catch(() => {
        simpleAlert({
          html: 'There has been an error confirming your booking',
          icon: 'error',
        })
      })
  }

  if (guestPrice <= 0) {
    return (
      <Button className="px-3 mr-2" onClick={confirm} disabled={disabled}>
        Confirm
      </Button>
    )
  }

  const pay = (ev: React.MouseEvent) => {
    ev.preventDefault()
    if (checkDuplicatedGuests([...freeGuests, ...paidGuests])) {
      simpleAlert({
        html: 'There cannot be duplicated guests',
        icon: 'warning',
      })
      return
    }

    if (paying) return
    loaderAlert({ html: 'preparing...' })

    const before = find({ type: 'before' }, extraNights)
    const after = find({ type: 'after' }, extraNights)
    const variables = {
      bookingId,
      freeGuests: freeGuests.map(fg => ({
        bookingId,
        guestBookingInput: { ...fg.guest },
        offeringAddOnId: fg.addon.id,
        bookingAddOnId: fg.bookingAddOnId,
      })),
      paidGuests: paidGuests.map(pg => ({
        bookingId,
        guestBookingInput: { ...pg.guest },
        offeringAddOnId: pg.option.offeringAddOnId,
        offeringAddOnOptionId: pg.option.id,
        bookingAddOnId: pg.bookingAddOnId,
      })),
      extraNights: {
        bookingId,
        date: customBookingDate,
        duration: customBookingDuration,
        addonNightsAfterId: after?.offeringAddOnId,
        optionNightsAfterId: after?.id,
        addonNightsBeforeId: before?.offeringAddOnId,
        optionNightsBeforeId: before?.id,
      },
      addonsToDelete: uniq(addonsIdsToDelete),
    }

    payBooking({ variables })
      .then(({ data }) => {
        const resp = data?.addAllAddonsToBooking
        Swal.close()

        if (resp?.errors.length > 0) {
          errorAlert(resp?.errors, 'there has been an error sending your data')
        } else {
          history.push(`/booking/${bookingId}/payment`)
        }
      })
      .catch(() => {
        simpleAlert({
          html: 'There has been an error confirming your booking',
          icon: 'error',
        })
      })
  }

  return (
    <div className="d-flex flex-column-reverse align-items-end flex-wrap">
      <p className="mb-2">
        Total: <FormatNumber n={guestPrice} />
      </p>
      <Button disabled={disabled} className="px-5" onClick={pay}>
        {paymentText}
      </Button>
    </div>
  )
}

export const DeclineRSVP: React.FC<{ booking: Booking }> = ({ booking }) => {
  const [decline, { loading }] = useMutation(DECLINE_BOOKING)
  const {
    onBehalfOfGuest,
    availableAddons: { extraFreeGuestAddon },
  } = useAppSelector(state => state.booking)

  const handleClick = (ev: React.MouseEvent) => {
    ev.preventDefault()
    if (loading) return

    confirmAlert({
      title: 'Decline RSVP',
      html: `Are you sure you'd like to decline this RSVP?`,
    }).then(resp => {
      if (!resp.value) return

      loaderAlert({ html: 'declining...' })

      const variables =
        extraFreeGuestAddon && onBehalfOfGuest
          ? {
              id: booking.id,
              guestId: onBehalfOfGuest.id,
              offeringAddOnId: extraFreeGuestAddon.id,
            }
          : {
              id: booking.id,
            }
      decline({ variables })
        .then(({ data }) => {
          const pay = data?.confirmBooking
          Swal.close()

          if (pay?.errors.length > 0) {
            errorAlert(
              pay?.errors,
              'there has been an error declining your reservation'
            )
          }
        })
        .catch(() => {
          simpleAlert({
            html: "it wasn't possible to cancel this RSVP. Please try again later.",
            icon: 'error',
          })
        })
    })
  }

  if (booking.status !== BookingStatus.Created) return null
  return (
    <Button className="px-3" onClick={handleClick} variant="outline-secondary">
      Decline
    </Button>
  )
}
