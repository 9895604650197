import axios from 'axios'

type HookType = 'marketing' | 'pending'
export const callWebhook = ({
  hook,
  params,
}: {
  hook: HookType
  params: Record<string, unknown>
}): void => {
  const hooks: Record<HookType, string> = {
    marketing: 'https://hook.us2.make.com/c2qljvjyalarvu93ankniwzi3knueyq7',
    pending: '',
  }

  axios
    .post(hooks[hook], params)
    .then(() => {
      // eslint-disable-next-line no-console
      console.info('Webhook called successfully')
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error('Error calling webhook', error)
    })
}
