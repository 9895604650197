import React, { Suspense, lazy, useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import { Helmet } from 'react-helmet'
import { useQuery } from '@apollo/react-hooks'
import { useHistory, useParams } from 'react-router-dom'

import Arrow from '../../assets/images/icons/arrow.svg'
import { GUEST } from '../../graphql'
import { Common404 } from '../../components/Error404'
import { ISignInUpPreloadedData, Guest } from '../../types'
import { SimpleLoader, SignUpSignInForValidations } from '../../components'
import { guestStatuses, myHub } from '../../common/constants'
import {
  saveInvitationData,
  saveSelectedEvent,
  useCurrentUser,
  useReadToken,
  useTrackView,
} from '../../common'

import { RSVPcontainer } from './style'
import { PreInvite } from './components'

const InvitationBody = lazy(() => import('./components/InvitationBody'))

export const ExternalUseInvitation: React.FC = () => {
  const history = useHistory()
  const { track } = useTrackView()
  const { isAuthenticated } = useCurrentUser()
  const { token } = useParams<{ token: string }>()
  const [registerModal, openRegisterModal] = useState(false)
  const [preloadedData, setPreloadedData] = useState<ISignInUpPreloadedData>()
  const { payload } = useReadToken(token)
  const headers = { authorization: `Bearer ${token}` }
  const { data, loading } = useQuery(GUEST, {
    context: { headers },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!data?.me.id || !payload?.source) return
    track({
      eventId: data?.me.event.id,
      guestId: data?.me.id,
      source: payload?.source as string,
      destination: 'event invitation',
    })

    // if we add track to the dependency array, it will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.me.id, payload?.source])

  if (loading) return <SimpleLoader />
  if (!data && !loading) return <Common404 text="The invitation is invalid" />

  const { accepted } = guestStatuses
  const guest: Guest = data.me
  const booked = guest.bookedComponents > 0
  const email = guest.email ?? ''
  const { firstName, lastName, status } = guest

  if (!preloadedData) {
    setPreloadedData({ email, firstName, lastName })
  }

  const eventComponents = guest.eventComponents ?? []
  const mainComponent = guest.mainComponent ?? eventComponents[0]

  const { date, duration } = mainComponent
  const { description, mainImage, name, id: eventId } = guest.event
  const event = {
    id: eventId,
    date,
    duration,
    name,
    description,
    mainImage,
  }
  const isAccepted = guest?.status === accepted

  const redirect = () => {
    saveSelectedEvent(eventId)
    if (isAccepted) {
      history.push(myHub)
    } else {
      history.push(`/invitation/${eventId}`)
    }
  }

  // redirecting the change status logic to the internal page since the endpoint is validated for customers
  // also validates that it is an own invation and not a third user changing it
  if (isAuthenticated) redirect()

  const handleClick = (ev: React.MouseEvent) => {
    ev.preventDefault()
    if (!isAuthenticated) {
      openRegisterModal(true)
    } else {
      redirect()
    }
  }

  // save event data in session storage to use it in the register modal
  saveInvitationData({ event, tokenPayload: payload })
  return (
    <RSVPcontainer className="pt-0 pt-sm-4 container">
      <Helmet>
        <title>{name} - Kiss & Tell</title>
        <meta
          name="description"
          content={`You're invited to ${name}. RSVP to the event and get all the details.`}
        />
      </Helmet>
      {!booked && <PreInvite data={guest} preloadedData={preloadedData} />}

      {booked && (
        <Suspense fallback={<SimpleLoader />}>
          <InvitationBody
            firstName={firstName}
            mainProvider={mainComponent.offering.provider}
            eventComponents={eventComponents}
            event={event}
            guest={{
              firstName,
              lastName,
              email,
              status,
            }}
            kidsAllowed={guest.kidsAllowed}
            plusOne={guest.plusOne}
          >
            <>
              <Button onClick={handleClick} className="rounded-pill">
                {isAccepted ? 'Go To Event' : 'Join Event'}
              </Button>
              <h6
                className="mx-auto mt-4 position-relative fwsb"
                style={{ maxWidth: '350px' }}
              >
                Book your room, get updates
                <br />& chat with the group
                <img
                  src={Arrow}
                  alt="->"
                  className="arrow-rsvp h-auto position-absolute"
                  style={{
                    width: '40px',
                    transform: 'scaleX(-1) rotate(-45deg)',
                    top: '-28px',
                    right: '65px',
                  }}
                />
              </h6>
            </>
          </InvitationBody>
        </Suspense>
      )}

      <SignUpSignInForValidations
        open={registerModal}
        onClose={() => openRegisterModal(false)}
        title="Sign up or sign in continue"
        text={`You must sign up or sign in to continue<br />Log in with your invitation email address to ${name}.`}
        preloadedData={{
          email: data?.email,
          firstName: data?.firstName,
          lastName: data?.lastName,
        }}
        afterLoginCallback={() => redirect()}
      />
    </RSVPcontainer>
  )
}
