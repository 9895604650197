import React, { useEffect, useContext } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useParams, useHistory } from 'react-router-dom'

import AppContext from '../../context'
import { useAppDispatch } from '../../hooks'
import { GET_USER_TOKEN } from '../../graphql'
import { setCurrentEventId } from '../../features/siteSlice'
import {
  errorAlert,
  saveSelectedEvent,
  simpleAlert,
  useQueryParams,
} from '../../common'

export const RedirectAutoLogin: React.FC = () => {
  const history = useHistory()
  const { auth } = useContext(AppContext)
  const [submitAutoLogin, { loading }] = useMutation(GET_USER_TOKEN)
  const { url } = useQueryParams()
  const { token, event } = useParams<{ token: string; event: string }>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loading || !token) return

    submitAutoLogin({ variables: { token } })
      .then(({ data }) => {
        const errors = data?.getUserToken?.errors || []
        if (errors.length > 0) {
          errorAlert(errors, 'It seems that the link is invalid.')
        } else {
          auth.signInWithoutRequest(data.getUserToken.result)
          saveSelectedEvent(event)
          dispatch(setCurrentEventId(event))
          history.push(url || '/')
        }
      })
      .catch(() => {
        simpleAlert({
          html: 'It seems that the link is invalid.',
          icon: 'error',
        })
      })

    // no matter what, we want to call the submitAutoLogin only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div className="text-center py-5">redirecting...</div>
}
