import styled from 'styled-components'

import {
  salmon,
  cardBorder,
  lightGray,
  darkGray,
  lightSalmon,
  gray,
} from '../../styles/themeColors'

export const SingleRoomCard = styled.article`
  background-color: white;
  border: 1px solid ${cardBorder};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 260px;
  min-height: 386px;
  width: 96%;
  &.margin {
    margin: 0 20px 20px 0;
  }
  & .cover {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px 5px 0 0;
    height: 135px;
    margin: 0 0 33px 0;
    position: relative;
    width: 100%;
    & span {
      align-items: center;
      background-color: ${salmon};
      border-radius: 50%;
      box-shadow: 0 2px 4px 0 #c3c3c3;
      bottom: -30px;
      display: flex;
      height: 60px;
      justify-content: center;
      position: absolute;
      right: 12px;
      width: 60px;
    }
  }
  & .data {
    & .price {
      border-bottom: 1px solid ${cardBorder};
      margin: 0 0 8px 0;
      padding-bottom: 8px;
    }
    & .occupancy-cont {
      border-bottom: 1px solid ${cardBorder};
      margin: 0 0 8px 0;
      padding-bottom: 8px;
      & .occupancy {
        font-weight: 600;
        margin: 0;
        & .cursor {
          margin-left: 5px;
          position: relative;
          top: -1px;
        }
      }
    }
    & .size {
      border-bottom: 1px solid ${cardBorder};
      padding-bottom: 10px;
    }
    & .btn.btn-primary {
      width: fit-content;
    }
  }
  @media (max-width: 991px) {
    margin: 0 0 20px 0 !important;
  }
  @media (max-width: 450px) {
    max-width: 100%;
    width: 100%;
    & .cover {
      border-radius: 5px 5px 0 0;
      height: 220px;
    }
  }
`

export const SelectRoom = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 10px;
  & input {
    border: 1px solid rgb(219, 219, 219);
    border-radius: 4px;
    font-size: 13px;
    height: 40px;
    padding: 8px 5px;
    width: 45px;
  }
  & p {
    font-size: 13px;
  }
`

export const ExpandGallery = styled.span`
  background-color: white;
  border-radius: 30px;
  font-weight: 600;
  padding: 3px 15px;
  position: absolute;
  right: 20px;
  top: 90px;
  z-index: 1;
`

export const VanityBoxContainer = styled.div`
  background-color: ${lightGray};
  border-radius: 6px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1) inset;
  margin: 0 auto;
  max-width: 400px;
  padding: 10px 0 0 0;
  & .info-list {
    padding: 10px 0 3px 0;
    & li {
      align-items: center;
      border-top: 1px solid ${darkGray};
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      padding: 7px 20px;
      width: 100%;
      & span {
        align-items: center;
        background-color: ${salmon};
        border-radius: 50%;
        color: ${lightSalmon};
        display: flex;
        font-weight: 700;
        height: 22px;
        justify-content: center;
        user-select: none;
        width: 22px;
      }
      & .dropdown {
        & .dropdown-toggle {
          color: ${darkGray};
          border: none;
          font-weight: 600;
          padding-bottom: 2px !important;
          &::after {
            margin-left: 0.5em;
            vertical-align: middle;
          }
        }
        & .dropdown-toggle:focus,
        & .dropdown-toggle:hover {
          background-color: ${lightGray} !important;
          box-shadow: none !important;
          color: ${darkGray} !important;
          outline: none !important;
        }
      }
    }
  }
  & .manage-profile-picture {
    margin: 0 auto;
    position: relative;
    width: fit-content;
    & img {
      border-radius: 50%;
      height: 100px;
      padding: 8.5px;
      width: 100px;
    }
    & .upload-icon {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      display: none;
      position: absolute;
      right: 5px;
      top: 5px;
    }
    &:hover .upload-icon {
      display: inline-block;
    }
  }
`

export const LocationOverviewContainer = styled.div`
  width: 100%;
  & .LocationOverview {
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin: 1px auto 10px auto;
    max-width: 700px;
    text-align: center;
    width: 90%;
    & > div {
      padding: 15px 0;
    }
    & span {
      display: block;
      color: ${gray};
      &.name {
        color: ${darkGray};
      }
    }
    & .time {
      border-left: 1px solid ${cardBorder};
      padding-left: 20px;
    }
  }
`
