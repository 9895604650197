import React from 'react'
import Swal from 'sweetalert2'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { TbDiamond } from 'react-icons/tb'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import { MdAccountCircle, MdMailOutline, MdSmartphone } from 'react-icons/md'

import {
  useMoveToTop,
  IframeYoutube,
  simpleAlert,
  loaderAlert,
} from '../../common'
import { Error } from '../../types'
import { KnTField, Wave } from '../../components'
import { salmon, pink } from '../../styles/themeColors'
import { useForm } from '../../common/alterForms'
import { CREATE_MARKETING_FORM } from '../../graphql'
import clearcut from '../../assets/images/Partnership/clearcut.svg'
import { useSource } from '../../components/SigninSignup/components'
import collage from '../../assets/images/Partnership/engagement_pic_collage.png'
import ClearCutLanding from '../../assets/images/Partnership/clearcutlandingpage.jpg'
import { TandCmodal } from '../Landingpages/components'
import { callWebhook } from '../../common/webhooks'

import {
  VerticalProgress,
  TheClearCutForm,
  ExclusiveTheClearCut,
  PromoVideo,
  HeaderSection,
} from './style'

export const TheClearCut: React.FC = () => {
  useMoveToTop()
  const [SaveReferralData, { loading }] = useMutation(CREATE_MARKETING_FORM)
  const initModel = {
    name: '',
    email: '',
    phone: '',
    clearCutCustomerNumber: '',
  }
  const { getSource } = useSource()
  const { model, errors, handleFieldChange, pushError, reset } =
    useForm(initModel)

  const sendForm = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    if (loading) return

    loaderAlert({ html: 'processing data, please wait a moment' })
    const variables = {
      input: {
        form_name: 'theclearcut',
        form_data: JSON.stringify(model),
      },
    }
    SaveReferralData({ variables })
      .then(({ data }) => {
        Swal.close()
        if (data.createMarketingForm.errors.length === 0) {
          simpleAlert({ html: 'Data sent successfully', icon: 'success' })
          reset(initModel)
        } else {
          data.createMarketingForm.errors.forEach(({ key, message }: Error) => {
            pushError(message, key)
          })
        }
      })
      .then(() => {
        callWebhook({
          hook: 'marketing',
          params: {
            data: JSON.stringify({
              ...variables,
              source: getSource(),
            }),
          },
        })
      })
  }

  return (
    <section className="public-container public-page">
      <Helmet>
        <title>Kiss & Tell | The Clear Cut</title>
        <meta
          name="description"
          content="Kiss & Tell Exclusive The Clear Cut Engagement Trip Deals"
        />
      </Helmet>
      <HeaderSection>
        <h1
          className="text-center text-white font-lf align-self-center pb-md-5 pt-4 p-md-0"
          style={{ fontSize: '100px' }}
        >
          They said yes,
          <br />
          Now you save!
        </h1>
        <div className="text-center pt-3 pb-5 pb-md-0">
          <img
            src={ClearCutLanding}
            alt="ClearCutLanding"
            className="screenshotHeader"
            width={690}
          />
        </div>
        <span>
          <Wave color="#ffffff" />
        </span>
      </HeaderSection>
      <section className="overflow-hidden pb-5">
        <h2 className="text-center pb-4 fwl" style={{ fontSize: '40px' }}>
          <span className="fwsb">Exclusive</span>{' '}
          <img src={clearcut} alt="the clear cut" width={340} /> Engagement Trip
          Deals
        </h2>

        <ExclusiveTheClearCut>
          <div>
            <img src={collage} alt="collage" className="collage" />
          </div>
          <div className="align-self-center px-3 px-sm-5 px-lg-4">
            <div className="d-flex align-items-stretch">
              <VerticalProgress>
                <IoMdCheckmarkCircle color="white" size={40} />
                <span className="active">2</span>
                <span>3</span>
              </VerticalProgress>
              <div className="list pl-5 pr-2 fwsb">
                <p className="m-0">
                  Book your discounted engagement trip
                  <span className="pl-3 fwn font-italic d-block">
                    <span>*up to 5 nights</span>
                  </span>
                </p>
                <p className="m-0 py-4">
                  Pop the question without breaking the bank!
                </p>
                <p className="m-0">
                  Reserve your big day and we’ll credit back your engagement
                  trip stay!
                </p>
              </div>
            </div>
            <TandCmodal />
          </div>
        </ExclusiveTheClearCut>
      </section>

      <Wave
        color={pink}
        style={{
          position: 'relative',
          bottom: '-1px',
        }}
      />
      <section style={{ backgroundColor: pink }}>
        <TheClearCutForm onSubmit={sendForm}>
          <h2 className="text-center mt-0 mb-4 fz-30">Celebrate & Save Now</h2>
          <div className="form w-90 mx-auto d-block">
            <Row>
              <Col xs={12} sm={6}>
                <KnTField
                  value={model}
                  error={errors}
                  setValue={handleFieldChange}
                  name="name"
                  placeholder="Your name*"
                  icon={<MdAccountCircle size={24} color={salmon} />}
                />
              </Col>
              <Col xs={12} sm={6}>
                <KnTField
                  value={model}
                  error={errors}
                  setValue={handleFieldChange}
                  name="email"
                  type="email"
                  placeholder="Your email*"
                  icon={<MdMailOutline size={24} color={salmon} />}
                />
              </Col>
              <Col xs={12} sm={6}>
                <KnTField
                  value={model}
                  error={errors}
                  setValue={handleFieldChange}
                  name="phone"
                  placeholder="Your phone number*"
                  icon={<MdSmartphone size={24} color={salmon} />}
                />
              </Col>
              <Col xs={12} sm={6}>
                <KnTField
                  value={model}
                  error={errors}
                  setValue={handleFieldChange}
                  name="clearCutCustomerNumber"
                  placeholder="The Clear Cut customer number*"
                  icon={<TbDiamond size={24} color={salmon} />}
                />
              </Col>
            </Row>
          </div>
          <Button type="submit" disabled={loading} className="send py-2 mt-5">
            Claim My Deal
          </Button>
        </TheClearCutForm>
      </section>
      <Wave
        color={pink}
        style={{
          transform: 'rotate(180deg)',
          position: 'relative',
          top: '-1px',
        }}
      />

      <div className="pt-5 pb-5 mt-4">
        <PromoVideo>
          <div className="px-3 px-sm-5">
            <IframeYoutube videoId="jAJuzPinbPo" />
          </div>
          <div className="px-3 px-sm-5 align-self-center pb-5 pb-lg-0">
            <h2 className="fwsb mb-4">
              When you’re ready to plan, we’ll get everyone there without the
              headache!
            </h2>
            <p className="m-0 fz-18">
              <Link to="/marketplace" className="fwsb">
                Explore our marketplace
              </Link>{' '}
              of top global destinations and seamlessly tie everything together
              with streamlined group planning, booking, communication and
              logistics for your big day!
            </p>
          </div>
        </PromoVideo>
      </div>
    </section>
  )
}
